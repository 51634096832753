import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
class MemberProfileFeed extends Component {
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.contact_information.length > 0) {
      dataFeed = this.props.dataFeed.contact_information.map(
        function(feedData, index) {
          return (
            <div>
              <div className="lineTabs">
                <div className="topicTabs">Contact Name</div>
                <div className="dataTabs">{feedData.name}</div>
              </div>
              <div className="lineTabs">
                <div className="topicTabs">Phone</div>
                <div className="dataTabs">{feedData.phone}</div>
              </div>
              <div className="lineTabs">
                <div className="topicTabs">Email</div>
                <div className="dataTabs">{feedData.email}</div>
              </div>
              <p className="line" />
            </div>
          );
        },

        this
      );
    } else {
      dataFeed = <RecordNotFound />;
    }

    return dataFeed;
  }
}

export default MemberProfileFeed;
