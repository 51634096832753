import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./header.css";
import logo from "./NDID_logo_transparent_header.png";
import person from "./person.png";
import { GetData, getImage } from "../../_services/";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loading: true,
      redirect: false,
      activeHome: "childMainMenu",
      activeApp: "childMainMenu",
      activeProcess: "childMainMenu",
      firstName : "",
      lastName : "",
      permission: {
        memu_visible: {
          application_submission: true,
          application_status: false,
          process: false
        }
      },
      dataRoles: {
        roles: {
          ndid_administrator: 0,
          it_administrator: 0,
          tester: 0,
          business: 0,
          settlement: 0
        }
      },
      image_url: "",

      my_profile: {
        picture: {
          filename: "",
          filecode: ""
        }
      }
    };
  }
  componentDidMount() {
    let path = window.location.pathname;
    if (this.props.authentication.loggedIn) {
      GetData("/token").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 0) {
          this.setState({ redirect: true });
        }
      });
      GetData("/profile/me").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {

          this.setState({ my_profile: responseJsonData.data,
          firstName: responseJsonData.data.firstname,
          lastName: responseJsonData.data.lastname,
         });
          if (responseJsonData.data.picture.filename) {
            
            getImage(
              "/file?filecode=" +
                responseJsonData.data.picture.filecode +
                "&type=profile"
            ).then(image => {
              this.setState({
                image_url: image
               
              });
            });
          }
        }
      });

      if (
        path === "/" ||
        path === "/home" ||
        path === "/news" ||
        path === "/profile" ||
        path === "/resource" ||
        path === "/aboutus" ||
        path === "/contact" ||
        path === "/faq"
      ) {
        this.setState({
          activeHome: "childMainMenu active",
          activeApp: "childMainMenu",
          activeProcess: "childMainMenu"
        });
      } else if (
        path === "/application-submission" ||
        path === "/application-status" ||
        path === "/membership-contract"
      ) {
        this.setState({
          activeHome: "childMainMenu",
          activeApp: "childMainMenu active",
          activeProcess: "childMainMenu "
        });
      } else if (
        path === "/process" ||
        path === "/process/sit" ||
        path === "/process/uat" ||
        path === "/process/application-review" ||
        path === "/process/nft" ||
        path === "/process/mqa"
      ) {
        this.setState({
          activeHome: "childMainMenu",
          activeApp: "childMainMenu",
          activeProcess: "childMainMenu active"
        });
      } else {
        this.setState({
          activeHome: "childMainMenu active",
          activeApp: "childMainMenu",
          activeProcess: "childMainMenu "
        });
      }
    }
    if (this.props.authentication.loggedIn) {
      if (!this.props.authentication.user.userData.roles) {
        this.renderRedirect();
      }
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/logout" />;
    }
  };
  _handleClick(menuItem) {
    if (menuItem === "menuHome") {
      this.setState({
        activeHome: "childMainMenu active",
        activeApp: "childMainMenu",
        activeProcess: "childMainMenu"
      });
    } else if (menuItem === "menuApp") {
      this.setState({
        activeHome: "childMainMenu ",
        activeApp: "childMainMenu active",
        activeProcess: "childMainMenu"
      });
    } else if (menuItem === "menuProcess") {
      this.setState({
        activeHome: "childMainMenu ",
        activeApp: "childMainMenu",
        activeProcess: "childMainMenu active"
      });
    } else {
      this.setState({
        activeHome: "childMainMenu active",
        activeApp: "childMainMenu",
        activeProcess: "childMainMenu"
      });
    }
  }

  render() {
    const { authentication, permission } = this.props;
    let content_header = "";
    if (permission.memu_visible !== undefined) {
      const userDetail = authentication.user.userData;
      let dataRoles = userDetail;
      let MenuApplicationSubmittion;
      if (authentication.user.userData.roles !== undefined) {
        dataRoles = authentication.user.userData;
        if (dataRoles.roles.business === 1) {
          MenuApplicationSubmittion = (
            <Link
              to="/application-submission"
              className="linkMainMenu"
              onClick={this._handleClick.bind(this, "menuApp")}
            >
              {" "}
              Member Application{" "}
            </Link>
          );
        } else {
          MenuApplicationSubmittion = (
            <Link
              to="/application-status"
              className="linkMainMenu"
              onClick={this._handleClick.bind(this, "menuApp")}
            >
              {" "}
              Member Application{" "}
            </Link>
          );
        }
      } else {
        dataRoles = this.state.dataRoles;
      }
      content_header = (
        <nav className="navmainMenu">
          {this.state.redirect && this.renderRedirect()}
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <Link
                to="/"
                className="logoWeb"
                onClick={this._handleClick.bind(this, "menuHome")}
              >
                <img
                  src={logo}
                  srcSet={logo}
                  alt="NDID Digital Identitfy for All"
                  className="imgLogoWeb"
                />
              </Link>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav mainMenu">
                {authentication.loggedIn === true && (
                  <li className={this.state.activeHome} id="menuHome">
                    <Link
                      to="/home"
                      className="linkMainMenu"
                      onClick={this._handleClick.bind(this, "menuHome")}
                    >
                      {" "}
                      Home{" "}
                    </Link>
                  </li>
                )}
                {authentication.loggedIn === true &&
                  (dataRoles.roles.business === 1 ||
                    dataRoles.roles.ndid_administrator === 1) && (
                    <li className={this.state.activeApp} id="menuApp">
                      {MenuApplicationSubmittion}
                    </li>
                  )}
                {authentication.loggedIn === true &&
                  (dataRoles.roles.business === 1 ||
                    dataRoles.roles.ndid_administrator === 1 ||
                    dataRoles.roles.tester === 1) &&
                  permission.memu_visible.process === true && (
                    <li
                      className={this.state.activeProcess}
                      onClick={this._handleClick.bind(this, "menuProcess")}
                      id="menuProcess"
                    >
                      <Link to="/process" className="linkMainMenu">
                        {" "}
                        Member Process{" "}
                      </Link>
                    </li>
                  )}
              </ul>

              <ul className="nav navbar-nav navbar-right profileMenu">
                {authentication.loggedIn === true && (
                  <li className="dropdown" id="menuAccount">
                    <a
                      href
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <div className="profilePreview">
                        {this.state.my_profile.picture.filename ? (
                          <img
                            src={this.state.image_url}
                            className="imgProfilePreview"
                            alt=""
                          />
                        ) : (
                          <img
                            src={person}
                            className="imgProfilePreview"
                            alt=""
                          />
                        )}
                      </div>
                      <span className="nameProfilePreview">
                        {this.state.firstName}  {this.state.lastName}
                      </span>
                      <i className="fa fa-angle-down" aria-hidden="true" />
                    </a>
                    <ul className="dropdown-menu width100">
                      <li
                        className="menuProfile"
                        id="menuProfile"
                        onClick={this._handleClick.bind(this, "menuHome")}
                      >
                        <Link
                          to="/profile"
                          className="linkProfileMenu menuProfile"
                        >
                          {" "}
                          Member Profile{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/change_password" className="linkProfileMenu">
                          {" "}
                          Change Password{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/logout" className="linkProfileMenu">
                          {" "}
                          Logout{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      );
    }

    return (
      <div>
        <div> {this.state.redirect && this.renderRedirect()}</div>
        {content_header}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  return {
    alert,
    authentication
  };
}

const connectedApp = connect(mapStateToProps)(Header);
export { connectedApp as Header };
