import React from "react";
import { Input } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./faqcss.css";
import { GetData } from "../../_services";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";

const Search = Input.Search;

class FAQPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      active: "",
      boxactive: "",
      activeId: null,
      data: 1,
      loading: true,
      callData: true,
      category_click: 0,
      current_method: 1,
      categoryValue: "",
      showContent: false,
      showCategory: true,
      expandId: 0,
      expandStatus: true
    };
    this.callbackPage = this.callbackPage.bind(this);
    this.togglebox = this.togglebox.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handelApply = this.handelApply.bind(this);
    this.expandFaqClick = this.expandFaqClick.bind(this);
    this.setActiveElement = this.setActiveElement.bind(this);
  }

  componentDidMount() {
    CheckPermission("/faq").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/faqs/category").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              categoryData: responseJsonData.data
            });
          }
        });
        GetData("/faqs").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataRows: responseJsonData.data.row,
              dataTotalPage: responseJsonData.data.total_page,
              dataTotalRecord: responseJsonData.data.total_record
            });
          }

        });
      }
    });
  }
  handleChangeKeyword(event) {
    this.setState({ keyword: event.target.value });
  }
  handelApply(value) {
    if(value.trim() !== ""){
      this.setState({
        showContent: true,
        showCategory: false,
        categoryValue: "",
        expandId: 0,
        keyword: value
      });
       this.callbackPage();
    }else{
      this.setState({
        showContent: false,
        showCategory: true,
        expandId: 0,
        activeId: null,
      });
    }
   
  }
  callbackPage() {
    this.setState({
      callData: true,
      show_res_message: false
    });

    setTimeout(() => {
      GetData(
        "/faqs/filter?category=" +
          this.state.categoryValue +
          "&keyword=" +
          this.state.keyword
      ).then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            current_method: 1,
            visible: false,
            dataRows: responseJsonData.data,
            dataTotalPage: responseJsonData.data.total_page,
            dataTotalRecord: responseJsonData.data.total_record
          });
        } else {
          this.setState({
            loading: false,
            callData: false,
            current_method: 1,
            visible: false,
            dataRows: []
          });
        }
      });
    }, 200);
  }

  addkeyword() {
    this.setState({ keyword: this.refs.keyword.value });
  }

  togglebox(e) {
    this.setState({
      showContent: true,
      activeId: e.currentTarget.getAttribute("data-id"),
      categoryValue: e.currentTarget.getAttribute("data-value"),
      expandId: 0,
      expandStatus: true
    });

    this.callbackPage();
  }
  setActiveElement(id) {
    this.setState({ activeId: id });
  }
  expandFaqClick(value, e) {
    let statusExpand = e.currentTarget.getAttribute("aria-expanded");
    // console.log(statusExpand);
    // let status = this.state.expandStatus;
    if (value === this.state.expandId) {
      if (statusExpand === "true") {
        this.setState({
          expandId: value,
        });
      } else {
        this.setState({
          expandId: 0,
        });
      }
    } else {
      this.setState({
        expandId: value,
      });
    }
  }

  render() {
    let bodyfaq;
    let lowerpage = "";
    let searchfaq;
    let content;
    if (this.state.data === 1) {
      if (this.state.callData === true) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        const arrayDataCategory = this.state.categoryData;
        if (arrayDataCategory) {
          const listCategory = arrayDataCategory.map((item, index) => (
            <div className="col-md-4 col-xs-6 faqchoosebox">
              <div
                className={
                  index === parseInt(this.state.activeId)
                    ? "faqboxhead active"
                    : "faqboxhead"
                }
                data-id={index}
                onClick={this.togglebox}
                data-value={item.id}
              >
                <label className="headerinbox">{item.title}</label>
                <label className="detailinbox">{item.description}</label>
              </div>
            </div>
          ));
          searchfaq = (
            <div className="fqaSearch">
              <div className="form-group col-md-7">
                <Search
                  placeholder="Type your keyword"
                  enterButton
                  value={this.state.keyword}
                  onChange={this.handleChangeKeyword}
                  size="large"
                  onSearch={this.handelApply}
                />
              </div>
            </div>
          );

          bodyfaq = <div className="faqchoose col-md-12">{listCategory}</div>;

          const arrayDataList = this.state.dataRows;
          if (arrayDataList) {
            let listDataList = arrayDataList.map(item => (
              <div className="panel panel-default">
                <a
                  role="button"
                  data-toggle="collapse"
                  className={
                    this.state.active === item.id
                      ? "check"
                      : "" || this.state.expandId === item.id
                      ? "faq-title-expand"
                      : "faq-title"
                  }
                  data-parent="#accordion"
                  href={"#" + item.id}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  onClick={(e) => this.expandFaqClick(item.id, e)}
                >
                  <div
                    className="panel-heading collapsenbox"
                    role="tab"
                    id="headingOne"
                  >
                    <h4 div className="panel-title collapsenhead">
                      {item.title}
                      <span className="arrowdown">
                        <i className="fa fa-angle-down" />
                      </span>
                    </h4>
                  </div>
                </a>
                <div
                  id={item.id}
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne"
                >
                  <div className="panel-body">{item.detail}</div>
                </div>
              </div>
            ));

            if (!listDataList.length) {
              listDataList = (
                <div>
                  No search result found. Please try another search, or &nbsp;
                  <Link to={"/contact"} className="faqnotfound">
                    contact us
                  </Link>
                </div>
              );
            }

            if (this.state.showContent && !this.state.showCategory) {
              lowerpage = (
                <div className="founddata col-md-12">
                  <span className="founddata-result-text">Search Result</span>
                  <hr className="founddata-result" />
                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    {listDataList}
                  </div>
                </div>
              );
            }else if(this.state.showContent && this.state.showCategory){
              lowerpage = (
                <div className="founddata col-md-12">
                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    {listDataList}
                  </div>
                </div>
              );
            }
          } else {
            lowerpage = <div />;
          }
        }
        content = (
          <div>
            <HeaderContent message="FAQs" />
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return (
      <div>
        {content}
        {searchfaq}
        {this.state.showCategory && bodyfaq}

        {lowerpage}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedFAQPage = connect(mapStateToProps)(FAQPage);
export { connectedFAQPage as FAQPage };
