import { GetData } from "../_services";
export function CheckPermission(url) {
  return new Promise((resolve, reject) => {
    GetData("/permission?url=" + url).then(result => {
      resolve(result.status);
    });
  });
}

export function CheckPermissionMenu(url) {
  return new Promise((resolve, reject) => {
    GetData("/menu-visible").then(result => {
      resolve(result);
    });
  });
}
