import React, { Component } from "react";
import { connect } from "react-redux";

class NotFound extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 well">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">
                Sorry, an error has occured, Requested page not found!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  return {
    alert,
    authentication
  };
}

const connectedApp = connect(mapStateToProps)(NotFound);
export { connectedApp as NotFound };
