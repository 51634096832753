import React from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import "./index.css";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { GetData } from "../../_services/";
import SummaryFeed from "./uat-summary-feed";
import { DownloadFiles } from "../../_services/data.service";
import OverviewFeedDocument from "../MemberProcessPage/Resource_feed_document";

const TabPane = Tabs.TabPane;

class ProcessUAT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true
    };
    this.callback = this.callback.bind(this);
  }
  componentDidMount() {
    CheckPermission("/process/uat").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/member-process/uat/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataOverview: responseJsonData.data,
              dataBody: responseJsonData.data["body"],
              dataWebsiteTest: responseJsonData.data["website_test"]
            });
          }
        });
      }
    });
  }
  callback(key) {
    this.setState({
      callData: true
    });
    if (key === "1") {
      GetData("/member-process/uat/overview").then(res => {
        let responseJsonData = res;

        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataOverview: responseJsonData.data,
            dataBody: responseJsonData.data["body"],
            dataWebsiteTest: responseJsonData.data["website_test"]
          });
        }
      });
    } else if (key === "2") {
      GetData("/member-process/uat/summary").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataSummary: responseJsonData
          });
        }
      });
    }
  }
  viewDetail = e => {
    let data = this.state.dataOverview.document[
      e.target.getAttribute("document-id")
    ];
    this.setState({
      visible: true,
      dataDetail: data
    });
  };
  viewDetailSummary = e => {
    let data = this.state.dataSummary.data[e.target.getAttribute("data-id")];
    this.setState({
      visible: true,
      dataDetailSummary: data
    });
  };
  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");
    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=document_public",
      name
    );
  };
  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            {this.state.callData === true && <LoadPage />}
            <HeaderContent message="UAT" />
            <div className="uatBlock">
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Overview" key="1" className="titleTabs">
                  {this.state.callData === false ? (
                    <frameElement>
                      <div className="detailBlock">
                        <p>
                          {" "}
                          User Acceptance Testing (UAT) เป็นขั้นตอนการทดสอบผ่านแอปพลิเคชันของสมาชิก เพื่อทดสอบว่าสมาชิกสามารถให้บริการผ่านแอปพลิเคชันที่สมาชิกแจ้งแก่ทาง NDID ไว้ได้หรือไม่
                        </p>
                        <div className="mt-20">
                        การทดสอบ UAT มีเงื่อนไขและขั้นตอนดังต่อไปนี้
                        </div>

                        <ul className="list-none">
                          <li>
                          1.	สมาชิกผ่านการทดสอบ SIT และลงนาม SIT Sign-off Document เรียบร้อยแล้ว 
                          </li>
                          <li>
                          2.	สมาชิกสามารถดาวน์โหลดเอกสารวิธีการ setup ระบบสำหรับการทดสอบ UAT ได้ตาม link ด้านล่าง 
                          </li>
                          <li>3.	สมาชิกแจ้งรอบที่ต้องการทดสอบ UAT ให้ NDID ทราบทางอีเมล (it_support_round2@ndid.co.th)</li>
                          <li>
                          4.	หลังจากสมาชิกผ่านการทดสอบ Test Case ที่เป็น Case บังคับทั้งหมด ทาง NDID จะออกเอกสารสำหรับ UAT Sign-Off ให้กับสมาชิก
                          </li>
                          <li>
                          5.	สมาชิกลงนามเอกสารสำหรับ Sign-Off ให้เรียบร้อย 
                          </li>
                          <li>6.	สมาชิกจะต้องทดสอบ UAT ให้เสร็จสิ้นภายในระยะเวลาที่กำหนด นับจากเริ่มทดสอบ มิฉะนั้นจะถือว่าไม่ผ่านการทดสอบ และต้องเริ่มกระบวนการทดสอบใหม่</li>
                        </ul>

                        <div className="documentBlock">
                          <p className="titleDocument">
                            เว็บไซต์สำหรับทดสอบ UAT
                          </p>
                          <ul className="testDoccumentFile linkI">
                            <li className="childDocument">
                              {/* <a
                                href
                                target="_blank"
                                rel="noopener noreferrer"
                                className="documentItem color2"
                              > */}
                                <i
                                  className="fa fa-file-text faFileText color1"
                                  aria-hidden="true"
                                />
                                {"TBD"}
                                <i
                                  className="fa fa-external-link faExternalLink"
                                  aria-hidden="true"
                                />
                              {/* </a> */}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="documentBlock">
                        <p className="titleDocument">
                          เอกสารประกอบการทดสอบ UAT
                        </p>
                        <ul className="testDoccumentFile">
                          {this.state.callData === false && (
                            <OverviewFeedDocument
                              dataFeed={this.state.dataOverview}
                            />
                          )}
                        </ul>
                      </div>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
                <TabPane tab="Summary" key="2">
                  {this.state.callData === false ? (
                    <frameElement>
                      <div className="app2">
                        <div className="tableWidth">
                          <table className="table table-striped tableSummary">
                            <thead className="trHeaderColor">
                              <tr>
                                <th scope="col">Role</th>
                                <th scope="col">Mode</th>
                                <th scope="col">Status</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">Finish Date</th>
                                <th scope="col">Pair with</th>
                                <th scope="col">Skipped</th>
                                <th scope="col">Failed</th>
                                <th scope="col">Passed</th>
                                <th scope="col">Progress</th>
                              </tr>
                            </thead>

                            <tbody>
                              {this.state.callData === false && (
                                <SummaryFeed
                                  dataFeed={this.state.dataSummary}
                                  viewDetail={this.viewDetailSummary}
                                />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div className="col-12">{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedProcessUAT = connect(mapStateToProps)(ProcessUAT);
export { connectedProcessUAT as ProcessUAT };
