import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.css";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { DownloadFiles } from "../../_services/data.service";
import { GetData } from "../../_services/";
import OverviewFeedRole from "./overview_feed_role";
import OverviewFeedFile from "./Resource_feed";

class ProcessOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      dataOverview: {
        body: "",
        file: "",
        summary: {
          sit: {
            member_process_id: "",
            application_submission_id: "",
            total_case: "",
            status: "",
            skipped_case: "",
            failed_case: "",
            passed_case: "",
            progress: "",
            roles: []
          },
          uat: {
            member_process_id: "",
            application_submission_id: "74",
            total_case: "",
            status: "",
            skipped_case: "",
            failed_case: "",
            passed_case: "",
            progress: "",
            roles: []
          },
          ar: {
            member_process_id: "",
            application_submission_id: "",
            total_case: "",
            status: "",
            skipped_case: "",
            failed_case: "",
            passed_case: "",
            progress: "",
            roles: []
          },
          nft: {
            member_process_id: "",
            application_submission_id: "",
            total_case: "",
            status: "",
            skipped_case: "",
            failed_case: "",
            passed_case: "",
            progress: "",
            roles: []
          },
          mqa: {
            member_process_id: "",
            application_submission_id: "",
            total_case: "",
            status: "",
            skipped_case: "",
            failed_case: "",
            passed_case: "",
            progress: "",
            roles: []
          }
        }
      }
    };
  }
  componentDidMount() {
    CheckPermission("/process").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/member-process/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataOverview: responseJsonData.data
            });
          }
        });
      }
    });
  }
  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=document_public",
      name
    );
  };

  render() {
    let content;

    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            <HeaderContent message="Overview" />
            <div className="overviewBlock">
              <div className="blogSet">
                <p className="line-bottom" />

                <ul className="p-5">
                  <li className="list-none">
                    <p>การทดสอบ เพื่อใช้บริการการเชื่อมต่อผ่าน NDID Plat Form มีขั้นตอน ดังนี้</p>
                    <table>
                      <tr>
                        <td style={{padding: "0px 20px"}}>1) SIT (System Integration Testing)</td>
                        <td style={{padding: "0px 20px"}}>ช่วงเวลา 1 ก.ค. 62 – 31 ส.ค. 62</td>
                      </tr>
                      <tr>
                        <td style={{padding: "0px 20px"}}>2) Application Review</td>
                        <td style={{padding: "0px 20px"}}>ช่วงเวลา 1 ส.ค. 62 – 31 ส.ค. 62</td>
                      </tr>
                      <tr>
                        <td style={{padding: "0px 20px"}}>3) UAT (User Acceptance Testing)</td>
                        <td style={{padding: "0px 20px"}}>ช่วงเวลา 1 ก.ย. 62 -  26 ต.ค. 62</td>
                      </tr>
                      <tr>
                        <td style={{padding: "0px 20px"}}>4) NFT (Non-Functional Testing)</td>
                        <td style={{padding: "0px 20px"}}>ช่วงเวลา  5 พ.ย. 62 – 6 ธ.ค. 62</td>
                      </tr>
                      <tr>
                        <td style={{padding: "0px 20px"}}>5) MQA (Membership Qualification Assessment)</td>
                        <td style={{padding: "0px 20px"}}>ช่วงเวลา 5 พ.ย. 62 – 6 ธ.ค. 62</td>
                      </tr>
                    </table>
                    <br />
                    <p><i>หมายเหตุ : บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไข รายละเอียดข้อมูล และยกเลิกได้ตามความเหมาะสม โดยจะแจ้งให้ทราบล่วงหน้า</i></p>
                  </li>
                </ul>
              </div>
              <div className="documentBlock">
                <p className="titleDocument">เอกสารประกอบการทดสอบ</p>

                <ul className="testDoccumentFile">
                  <OverviewFeedFile dataFeed={this.state.dataOverview} />
                </ul>
              </div>

              <div className="documentBlock">
                <p className="titleDocument">เว็บไซต์สำหรับแจ้ง Issue</p>
                <ul className="testDoccumentFile linkI">
                  <li className="childDocument">
                    <a
                      href="https://ndid-its.southeastasia.cloudapp.azure.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="documentItem color2"
                    >
                      <i
                        className="fa fa-file-text faFileText color1"
                        aria-hidden="true"
                      />
                      Issue Tracker
                      <i
                        className="fa fa-external-link faExternalLink"
                        aria-hidden="true"
                        style={{float: 'right', marginTop: 7, marginRight: 15}}
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="documentBlock">
                <p className="titleDocument">Test Dashboard</p>
                <div className="box-card-center">
                  <div className="summaryCardItems">
                    <div className="summaryCardInfo">
                      <h5>SIT</h5>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Roles:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p>
                            <OverviewFeedRole
                              data={this.state.dataOverview.summary.sit.roles}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Status:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo" style={{textTransform: 'capitalize'}}>
                            {" "}
                            {this.state.dataOverview.summary.sit.status}{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Skipped:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {
                              this.state.dataOverview.summary.sit.skipped_case || '-'
                            }{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Failed:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {
                              this.state.dataOverview.summary.sit.failed_case || '-'
                            }{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Passed:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {
                              this.state.dataOverview.summary.sit.passed_case || '-'
                            }{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Progress:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {this.state.dataOverview.summary.sit.progress}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/process/sit" className="btnViewMore">
                      View More
                    </Link>
                  </div>

                  <div className="summaryCardItems">
                    <div className="summaryCardInfo">
                      <h5>UAT</h5>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Roles:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p>
                            <OverviewFeedRole
                              data={this.state.dataOverview.summary.uat.roles}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Status:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo" style={{textTransform: 'capitalize'}}>
                            {" "}
                            {this.state.dataOverview.summary.uat.status}{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Skipped:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {
                              this.state.dataOverview.summary.uat.skipped_case || '-'
                            }{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Failed:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {
                              this.state.dataOverview.summary.uat.failed_case || '-'
                            }{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Passed:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {
                              this.state.dataOverview.summary.uat.passed_case || '-'
                            }{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Progress:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo">
                            {" "}
                            {this.state.dataOverview.summary.uat.progress}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/process/uat" className="btnViewMore">
                      View More
                    </Link>
                  </div>
                  <div className="summaryCardItems">
                    <div className="summaryCardInfo">
                      <h5>Application Review</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Roles:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p>
                            <OverviewFeedRole
                              data={this.state.dataOverview.summary.ar.roles}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Status:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo" style={{textTransform: 'capitalize'}}>
                            {" "}
                            {this.state.dataOverview.summary.ar.status}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link
                      to="/process/application-review"
                      className="btnViewMore"
                    >
                      View More
                    </Link>
                  </div>

                  <div className="summaryCardItems">
                    <div className="summaryCardInfo">
                      <h5>NFT</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Roles:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p>
                            <OverviewFeedRole
                              data={this.state.dataOverview.summary.nft.roles}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Status:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo" style={{textTransform: 'capitalize'}}>
                            {" "}
                            {this.state.dataOverview.summary.nft.status}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/process/nft" className="btnViewMore">
                      View More
                    </Link>
                  </div>

                  <div className="summaryCardItems">
                    <div className="summaryCardInfo">
                      <h5>Membership Qualification Assessment</h5>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Roles:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p>
                            <OverviewFeedRole
                              data={this.state.dataOverview.summary.mqa.roles}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-3">
                          <p className="pBold role-height">Status:</p>
                        </div>
                        <div className="col-lg-9 col-md-9 col-xs-">
                          <p className="textInfo" style={{textTransform: 'capitalize'}}>
                            {" "}
                            {this.state.dataOverview.summary.mqa.status}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/process/mqa" className="btnViewMore">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedProcessOverview = connect(mapStateToProps)(ProcessOverview);
export { connectedProcessOverview as ProcessOverview };
