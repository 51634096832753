import React, { Component } from "react";
import FeedRoleMulti from "../../_components/FeedRole/feedrolemulti";
class OverviewFeedRole extends Component {
  render() {
    let dataFeed = "";
    if (this.props.data.length > 0) {
      dataFeed = <FeedRoleMulti data={this.props.data} />;
    } else {
      dataFeed = "-";
    }
    return dataFeed;
  }
}
export default OverviewFeedRole;
