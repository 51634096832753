import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./application.css";
import { GetData } from "../../_services/";
import { PostFromData, DownloadFiles } from "../../_services/data.service";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import OverviewFeedFile from "../MemberProcessPage/Resource_feed";
import { Modal, Checkbox, Row, Col } from "antd";
import processImg from "./application-process.jpg";

const defaultCheckedList = [];
class ApplicationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      file: [],
      fileApplication: "",
      ndid_role: [],
      submitted: false,
      response_status: 2,
      response_message: "",
      fileApplicationValue: "",
      redirecttostatus: false,
      checkedList: defaultCheckedList,
      dataRole: [],
      dataOverview: {
        file: []
      }
    };

    this.applicationUploadRef = React.createRef();
    this.supportUploadRef = React.createRef();

    this.onChangeFileApplication = this.onChangeFileApplication.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  state = { visible: false };
  componentDidMount() {
    CheckPermission("/application-submission").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/ndid-role/application").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataRole: responseJsonData.data
            });
          }
        });

        GetData("/process-resource/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataOverview: responseJsonData.data
            });
          }
        });
      }
    });
  }

  showModal = () => {
    if (this.applicationUploadRef.current) {
      this.applicationUploadRef.current.value = '';
    }

    if (this.supportUploadRef.current) {
      this.supportUploadRef.current.value = '';
    }

    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
      file: [],
      fileApplication: "",
      ndid_role: [],
      submitted: false,
      response_status: 2,
      response_message: "",
      fileApplicationValue: "",
      checkedList: []
    });
  };

  renderRedirect = () => {
    if (this.state.redirecttostatus) {
      return <Redirect to="/application-status" />;
    }
  };

  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit

    this.setState({ submitted: true });
    const { ndid_role, file, fileApplication } = this.state;

    if (ndid_role.length !== 0 && fileApplication.length > 0) {
      const data = new FormData();

      if (fileApplication) {
        data.append("application", fileApplication[0]);
      }

      if (ndid_role) {
        data.append("ndid_role_id", ndid_role);
      }

      if (file) {
        const result_document = Object.values(file);
        result_document.map((result_document_file, index) =>
          data.append("document", result_document_file)
        );
      }

      PostFromData("/application-submission", data).then(result => {
        let responseJson = result;
        this.setState({
          response_status: responseJson.status,
          response_message: responseJson.message
        });
        if (responseJson.status === 1) {
          this.setState({
            redirecttostatus: true
          });
        }
      });
    } else {
      this.setState({
        response_status: 0,
        response_message: "Please Input "
      });
    }
  }

  onChange = checkedValues => {
    this.setState({
      ndid_role: checkedValues,
      checkedList: checkedValues
    });
  };

  onChangeFile(e) {
    this.setState({
      file: this.state.file.concat(...e.target.files)
    });
    e.target.value = null;
  }
  onChangeFileApplication(e) {
    this.setState({
      fileApplicationValue: e.target.files[0].name,
      fileApplication: e.target.files
    });
  }

  removeFile(e) {
    var array = [...this.state.file];
    var index = e.target.getAttribute("data-id");
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ file: array });
    }
  }

  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=application_file",
      name
    );
  };

  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");
    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=document_public",
      name
    );
  };

  render() {
    let content;
    let fileList = "";
    let res_message = "";

    const enabled =
      this.state.ndid_role.length > 0 && this.state.fileApplication.length > 0;
    if (this.state.response_status === 0) {
      res_message = (
        <div className="alert alert-danger">{this.state.response_message}</div>
      );
    } else if (this.state.response_status === 1) {
      res_message = (
        <div className="alert alert-success">{this.state.response_message}</div>
      );
    }

    if (this.state.file) {
      fileList = this.state.file.map((file, index) => (
        <div className="item-file">
          <span className="file-name">{file.name} </span>

          <button type="button" data-id={index} onClick={this.removeFile}>
            <i data-id={index} className="fa fa-close" />
          </button>
        </div>
      ));
    }

    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        let dataRoleAdd;
        if (this.state.dataRole.length > 0) {
          dataRoleAdd = this.state.dataRole.map(function(dataRole) {
            return (
              <Col span={6}>
                <Checkbox value={dataRole.id}>{dataRole.display_name}</Checkbox>
              </Col>
            );
          });
        }

        content = (
          <div>
            <HeaderContent message="Application Submission" />

            <div className="col-md-12 applicationModal">
              <img src={processImg} alt="Application process" />
              <br />
              <br />
              
              <div className="textTitleLarge">
                เอกสารประกอบการสมัครสมาชิก NDID
              </div>
              {this.state.callData === false && (
                <frameElement>
                  <ul className="testDoccumentFile">
                    <OverviewFeedFile dataFeed={this.state.dataOverview} />
                  </ul>
                </frameElement>
              )}

              <div className="col-md-12">
                <button
                  type="button"
                  onClick={this.showModal}
                  className="buttonSubmit"
                >
                  Submit Application Form
                </button>
              </div>
            </div>

            <Modal
              title="Submit Application Form"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              className="applicationModal"
            >
              <form
                onSubmit={this.onFormSubmit}
                class="block-memberapplication-form"
              >
                {res_message}

                <div class="block-application-roles">
                  <label>
                    Roles<span className="colorRed">*</span>
                  </label>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={this.onChange}
                    value={this.state.checkedList}
                  >
                    <Row>{this.state.dataRole.length > 0 && dataRoleAdd}</Row>
                  </Checkbox.Group>
                </div>

                <div class="block-application-form">
                  <label>
                    Application Form<span className="colorRed">*</span>
                  </label>
                  <div className="form-group">
                    <div className="input-group input-file">
                      <input
                        type="text"
                        className="form-control input-upload-file"
                        readOnly
                        value={this.state.fileApplicationValue}
                      />
                      <label className="input-group-btn">
                        <span className="btn btn-upload">
                          Upload file
                          <input
                            type="file"
                            className="btn-upload-file"
                            onChange={this.onChangeFileApplication}
                            accept="image/*,.pdf,.zip,.doc,.docx"
                            ref={this.applicationUploadRef}
                          />
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* <div className="upload-application-form">
                    <input type="file" onChange={this.onChangeFileApplication} />
                  </div> */}
                </div>

                <div class="block-document-file">
                  <label>Supported File(s)</label>

                  <div className="upload-document-file">
                    {/* <input type="file" multiple onChange={this.onChangeFile} /> */}
                    <div className="form-group">
                      <div className="input-group input-file">
                        <input
                          type="text"
                          className="form-control input-upload-file"
                          readOnly
                        />
                        <label className="input-group-btn">
                          <span className="btn btn-upload">
                            Upload file
                            <input
                              type="file"
                              multiple
                              className="btn-upload-file"
                              onChange={this.onChangeFile}
                              accept="image/*,.pdf,.zip,.doc,.docx"
                              ref={this.supportUploadRef}
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="list-file">{fileList}</div>
                  </div>
                </div>

                <div className="block-btn-footer">
                  <button
                    type="reset"
                    className="btn-reset"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-submit"
                    disabled={!enabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }

    return (
      <div>
        {this.state.redirecttostatus && this.renderRedirect()} {content}
      </div>
    );
  }
}

/*export { ApplicationPage };*/
function mapStateToProps(state) {
  return {
    state
  };
}
const connectedApplicationPage = connect(mapStateToProps)(ApplicationPage);
export { connectedApplicationPage as ApplicationPage };
