import React from "react";
import { connect } from "react-redux";
import "./index.css";
import { Tabs } from "antd";
import { GetData } from "../../_services";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import ResourceFeed from "./ResourceFeed";
import { DownloadFiles } from "../../_services/data.service";
const TabPane = Tabs.TabPane;

class ResourcesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      resoruceData: [],
      ndid_concept: [],
      getting_ndid: [],
      other: []
    };
  }

  componentDidMount() {
    CheckPermission("/resource").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/resource").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              resoruceData: responseJsonData.data
            });
          }
        });
      }
    });
  }

  handelDownload = e => {
    let id = e.currentTarget.getAttribute("data-id");
    let name = e.currentTarget.getAttribute("data-value");
    let folder_name = e.currentTarget.getAttribute("data-name");
    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=resource&name_folder=" +
        folder_name,
      name
    );
  };

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            <HeaderContent message="Resources" />
            {this.state.callData === true && <LoadPage />}
            <Tabs defaultActiveKey="0">
              {this.state.resoruceData.map((item, index) => (
                <TabPane tab={item.name} key={index}>
                  <ResourceFeed
                    dataFeed={item.files}
                    handelDownload={this.handelDownload}
                    folderName={item.name}
                  />
                </TabPane>
              ))}
            </Tabs>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }

    return <div className="col-12 res">{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}
const connectedResourcesPage = connect(mapStateToProps)(ResourcesPage);
export { connectedResourcesPage as ResourcesPage };
