import React from "react";
import { connect } from "react-redux";
import "./index.css";
import { HeaderContent } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import { GetData } from "../../_services/";
import LoadPage from "../../_components/loading";
import MemberProfileFeed from "./member_profile_feed";
import MemberFeedRole from "./member_profile_feed_role";
class MemberProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      dataProfile: {
        basic_info: {
          organization_name_en: "",
          organization_name_th: "",
          organization_abbreviation: "",
          organization_address: "",
          supervisor_agency_name_en: "",
          supervisor_agency_name_th: ""
        },
        contact_information: [],
        ndid_role: {
          date_registration: "",
          role: []
        }
      }
    };
  }

  state = { visible: false };
  componentDidMount() {
    CheckPermission("/profile").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/memberprofile").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataProfile: responseJsonData.data
            });
          }
        });
      }
    });
  }

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            <HeaderContent message="Member Profile" />
            <div className="memberProfileTabs">
              <div className="basicTabs">
                <h4 className="headerTabs">Basic Information</h4>
                <div className="descTabs">
                  <div className="lineTabs">
                    <div className="topicTabs">Organization Name (EN)</div>
                    <div className="dataTabs">
                      {this.state.dataProfile.basic_info.organization_name_en}
                    </div>
                  </div>
                  <div className="lineTabs">
                    <div className="topicTabs">Organization Name (TH)</div>
                    <div className="dataTabs">
                      {this.state.dataProfile.basic_info.organization_name_th}
                    </div>
                  </div>
                  <div className="lineTabs">
                    <div className="topicTabs">Organization Abbreviation</div>
                    <div className="dataTabs">
                      {
                        this.state.dataProfile.basic_info
                          .organization_abbreviation
                      }
                    </div>
                  </div>
                  <div className="lineTabs">
                    <div className="topicTabs">Organization Address</div>
                    <div className="dataTabs">
                      {
                        this.state.dataProfile.basic_info
                          .organization_address
                      }
                    </div>
                  </div>
                  <div className="lineTabs">
                    <div className="topicTabs">Supervisor Agency Name (EN)</div>
                    <div className="dataTabs">
                      {
                        this.state.dataProfile.basic_info
                          .supervisor_agency_name_en
                      }
                    </div>
                  </div>
                  <div className="lineTabs">
                    <div className="topicTabs">Supervisor Agency Name (TH)</div>
                    <div className="dataTabs">
                      {
                        this.state.dataProfile.basic_info
                          .supervisor_agency_name_th
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="contactTabs">
                <h4 className="headerTabs">Contact Information</h4>
                <div className="descTabs">
                  {this.state.callData === false && (
                    <MemberProfileFeed dataFeed={this.state.dataProfile} />
                  )}
                </div>
              </div>
              <div className="rolesTabs">
                <h4 className="headerTabs">NDID Role</h4>
                <div className="descTabs">
                  <div className="lineTabs">
                    <div className="topicTabs"> Date Registration</div>
                    <div className="dataTabs">
                      {this.state.dataProfile.ndid_role.date_registration}
                    </div>
                  </div>
                  <div className="lineTabs">
                    <div className="topicTabs">Role</div>
                    <div className="dataTabs">
                      {this.state.callData === false && (
                        <MemberFeedRole
                          data={this.state.dataProfile.ndid_role.role}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  const { authentication, users } = state;
  return {
    authentication,
    users
  };
}

const connectedMemberProfile = connect(mapStateToProps)(MemberProfile);
export { connectedMemberProfile as MemberProfile };
