import React from "react";
import "./application.css";
import "./application-modal.less";
import { Modal } from "antd";
import { GetData } from "../../_services/";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import MembershipContractFeed from "./application-contract-feed";
import ApplicationFeedFile from "./application-status-feed-file";
import LoadPage from "../../_components/loading";
import { Tabs } from "antd";
import { DownloadFiles } from "../../_services/data.service";
import FeedRoleMulti from "../../_components/FeedRole/feedrolemulti";

const TabPane = Tabs.TabPane;
class MemberContractPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      dataContract: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      dataPayslip: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      dataDetail: {
        application_submission_id: "",
        organization_id: "",
        application_source_id: "",
        ndid_role_id: [],
        ndid_role_name: [],
        status: "",
        remark: null,
        approved_date: "",
        approved_by: null,
        active: "",
        created_at: "",
        application_file: {
          filecode: "",
          filename: ""
        },
        member_contact_file: []
      }
    };
    this.callback = this.callback.bind(this);
  }

  state = { visible: false, visibleSlip: false };
  componentDidMount() {
    CheckPermission("/membership-contract").then(result => {
      this.setState({
        loading: false,
        data: result
      });
      if (result === 1) {
        GetData("/member-contract?type=contract").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              callData: false,
              dataContract: responseJsonData
            });
          }
        });
      }
    });
  }

  callback(key) {
    this.setState({
      callData: true
    });
    if (key === "1") {
      GetData("/member-contract?type=contract").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            callData: false,
            dataContract: responseJsonData
          });
        }
      });
    } else if (key === "2") {
      GetData("/member-contract?type=payslip").then(res => {
        let responseJsonData = res;

        if (responseJsonData.status === 1) {
          this.setState({
            callData: false,
            dataPayslip: responseJsonData
          });
        }
      });
    }
  }
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  showPayslipModal = () => {
    this.setState({
      visibleSlip: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };
  handleOkPayslip = e => {
    this.setState({
      visibleSlip: false
    });
  };

  handleCancel = e => {
    this.setState({
      visiblePayslip: false,
      visible: false
    });
  };

  viewDetail = e => {
    let data = this.state.dataContract.data.row[
      e.target.getAttribute("data-id")
    ];

    if (data) {
      this.setState({
        visiblePayslip: false,
        visible: true,
        dataDetail: data
      });
    }
  };

  viewDetailPayslip = e => {
    let index = e.target.getAttribute("data-id");
    let data = this.state.dataPayslip.data.row[index];
    if (data) {
      this.setState({
        visible: false,
        visiblePayslip: true,
        dataDetail: data,
        dataPendingIndex: index
      });
    }
  };
  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=membership_contract",
      name
    );
  };

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div className="site-member-contract">
            <HeaderContent message="Membership Contract" />
            {this.state.callData === true && <LoadPage />}
            <Tabs
              defaultActiveKey="1"
              animated="false"
              onChange={this.callback}
            >
              <TabPane tab="Membership Contract " key="1">
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataContract.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}
                          <th className="width-160">Uploaded Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <MembershipContractFeed
                            dataFeed={this.state.dataContract}
                            viewDetail={this.viewDetail}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
              <TabPane tab="Payslip" key="2">
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataPayslip.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}
                          <th className="width-160">Uploaded Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <MembershipContractFeed
                            dataFeed={this.state.dataPayslip}
                            viewDetail={this.viewDetailPayslip}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
            </Tabs>
            <Modal
              title="View Membership Contract"
              visible={this.state.visible}
              onCancel={this.handleCancel}
              className="viewPayslipModal"
            >
              <div className="modalStatus modal-view-payslip">
                <table>
                  <tbody>
                    <tr>
                      <td width="40%">
                        <b>Roles:</b>
                      </td>
                      <td>
                        {this.state.dataDetail.ndid_role_id.length > 0 && (
                          <FeedRoleMulti
                            data={this.state.dataDetail.ndid_role_id}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Uploaded Date:</b>
                      </td>
                      <td>{this.state.dataDetail.created_at}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Contract File:</b>
                      </td>
                      <td>
                        <ul className="ulModal">
                          <ApplicationFeedFile
                            dataFeed={this.state.dataDetail.member_contact_file}
                            handelDownloadFile={this.handelDownloadFile}
                          />
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Modal>

            <Modal
              title="View Membership Payslip"
              visible={this.state.visiblePayslip}
              onCancel={this.handleCancel}
              className="viewPayslipModal"
            >
              <div className="modalStatus modal-view-payslip">
                <table>
                  <tbody>
                    <tr>
                      <td width="40%">
                        <b>Roles:</b>
                      </td>
                      <td>
                        {this.state.dataDetail.ndid_role_id.length > 0 && (
                          <FeedRoleMulti
                            data={this.state.dataDetail.ndid_role_id}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Uploaded Date:</b>
                      </td>
                      <td>{this.state.dataDetail.created_at}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Payslip File:</b>
                      </td>
                      <td>
                        <ul className="ulModal">
                          <ApplicationFeedFile
                            dataFeed={this.state.dataDetail.member_contact_file}
                            handelDownloadFile={this.handelDownloadFile}
                          />
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Modal>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

export { MemberContractPage };
