import React, { Component } from "react";
import { Button } from "antd";
import RecordNotFound from "../../_components/RecordNotFound";
import FeedRoleMulti from "../../_components/FeedRole/feedrolemulti";

class MQAFeed extends Component {
  render() {
    let dataFeed = "";

    if (this.props.dataFeed.data.full_test.length > 0) {
      dataFeed = this.props.dataFeed.data.full_test.map(
        function(feedData, index) {
          return (
            <tr>
              <td>
                <FeedRoleMulti data={feedData.role} />
              </td>
              <td width="42%">
                <a
                  href
                  data-id={feedData.filecode}
                  data-value={feedData.filename}
                  onClick={this.props.handelDownloadFile}
                >
                  {feedData.filename}
                </a>
              </td>
              <td>
                <span className="status-text">{feedData.status}</span>
              </td>
              <td>
                <Button
                  className="btnUpload"
                  onClick={this.props.showModal}
                  data={feedData.id}
                  data-id={index}
                >
                  <i className="fa fa-upload faFileUpload" aria-hidden="true">
                    {" "}
                    <font>Upload File</font>
                  </i>
                </Button>
              </td>
            </tr>
          );
        },

        this
      );
    } else {
      dataFeed = <RecordNotFound cols="4" />;
    }

    return dataFeed;
  }
}

export default MQAFeed;
