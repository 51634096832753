import React from "react";

import { connect } from "react-redux";
import { GetData, DownloadFiles } from "../../_services/";
import MQAFeed from "./MQA_feed";
import MQAFeedHistory from "./MQA_feed_history";
import "./index.css";
import { Modal, Tabs, Button, Checkbox, Row, Col } from "antd";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { PostFromData } from "../../_services/data.service";
import OverviewFeedDocument from "../MemberProcessPage/Resource_feed_document";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";

const SweetAlert = withSwalInstance(swal);

const TabPane = Tabs.TabPane;

const defaultCheckedList = [];
class MemberMQAPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      file: [],
      ndid_role: [],
      submitted: false,
      response_status: 2,
      response_add_status: 2,
      response_test_result_status: 2,
      response_update_status: 2,
      response_message: "",
      response_add_message: "",
      response_update_message: "",
      callData: true,
      DataOverview: "",
      DataTestResult: "",
      visibleAddFile: false,
      fileMQAadd_value: "",
      fileMQAValue: "",
      fileUpdateName: "",
      fileUpdate: "",
      showResponMes: false,
      datarole: [],
      roleRP: false,
      roleIdP: false,
      roleProxy: false,
      roleAS: false,
      roleTierRP: false,
      roleTierIdP: false,
      roleTierAS: false,
      showSucess: false,
      showError: false,
      checkedList: defaultCheckedList,
      dataDetail: {
        id: "",
        ndid_role_id: "",
        role: [],
        filename: "",
        filecode: "",
        upload_date: "",
        status: ""
      }
    };

    this.uploadRef = React.createRef();
    this.updateUploadRef = React.createRef();

    this.callback = this.callback.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitUpdate = this.onFormSubmitUpdate.bind(this);
    this.fileMQAadd = this.fileMQAadd.bind(this);
    this.onChangeFileUpdate = this.onChangeFileUpdate.bind(this);
    this.CallTestResult = this.CallTestResult.bind(this);
    this.onConfirmSuccess = this.onConfirmSuccess.bind(this);
  }
  componentDidMount() {
    CheckPermission("/process/mqa").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/member-process/mqa/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              callData: false,
              loading: false,
              response_status: responseJsonData.status,
              DataOverview: responseJsonData
            });
          }
        });
      }
    });
  }

  callback(key) {
    this.setState({
      callData: true
    });

    if (key === "1") {
      GetData("/member-process/mqa/overview").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            callData: false,
            loading: false,
            response_status: responseJsonData.status,
            DataOverview: responseJsonData
          });
        }
      });
    } else if (key === "2") {
      GetData("/member-process/mqa/test-result").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            callData: false,
            loading: false,
            response_test_result_status: responseJsonData.status,
            DataTestResult: responseJsonData,
            datarole: responseJsonData.roles
          });
        }
      });
    }
  }
  showModalAddFile = () => {
    if (this.uploadRef.current) {
      this.uploadRef.current.value = '';
    }

    this.setState({
      visibleAddFile: true,
      showResponMes: false
    });
  };
  showModal = e => {
    if (this.updateUploadRef.current) {
      this.updateUploadRef.current.value = '';
    }

    let data = this.state.DataTestResult.data.full_test[
      e.target.getAttribute("data-id")
    ];
    console.log(data);
    this.setState({
      visible: true,
      dataDetail: data,
      showResponMes: false
    });
  };
  handleOk = e => {
    this.setState({
      visible: false,
      visibleAddFile: false,
      fileMQAadd_value: "",
      fileMQAValue: "",
      fileUpdateName: "",
      fileUpdate: ""
    });
  };

  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles("/file?filecode=" + id + "&type=member_process_result", name);
  };

  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");
    DownloadFiles("/file?filecode=" + id + "&type=document_public", name);
  };

  handleCancel = e => {
    this.setState({
      visible: false,
      submitted: false,
      visibleAddFile: false,
      fileMQAadd_value: "",
      fileMQAValue: "",
      fileUpdateName: "",
      fileUpdate: "",
      ndid_role: [],
      checkedList: []
    });
  };
  handleCancelUpdate = e => {
    this.setState({
      visible: false,
      submitted: false,
      visibleAddFile: false,
      fileMQAadd_value: "",
      fileMQAValue: "",
      fileUpdateName: "",
      fileUpdate: "",
      ndid_role: [],
      checkedList: []
    });
  };

  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit

    this.setState({ submitted: true });
    const { ndid_role, fileMQAadd_value } = this.state;
    if (ndid_role.length > 0 && fileMQAadd_value.length > 0) {
      const data = new FormData();

      data.append("file", fileMQAadd_value[0]);
      data.append("ndid_role_id", ndid_role);

      PostFromData("/member-process/mqa/add", data).then(result => {
        let responseJson = result;
        if (responseJson.status === 1) {
          this.setState({
            fileMQAadd_value: "",
            fileMQAValue: "",
            defaultCheckedList: [],

            ndid_role_id: "",
            showSucess: true,
            response_message: responseJson.message
          });
        } else {
          this.setState({
            showError: true,
            response_message: responseJson.message
          });
        }
        this.setState({
          submitted: false,
          response_status: responseJson.status,
          response_message: responseJson.message
        });
      });
    } else {
      this.setState({
        response_status: 0,
        response_message: "Please Input "
      });
    }
  }

  onConfirmSuccess = () => {
    this.setState({
      showSucess: false,
      visibleCalcel: false,
      visible: false,
      calcelValue: "",
      remark_text: "",
      remark_box: false,
      pending_button: true
    });
    this.handleCancel();
    this.CallTestResult();
  };

  CallTestResult = () => {
    GetData("/member-process/mqa/test-result").then(res => {
      let responseJsonData = res;
      if (responseJsonData.status === 1) {
        this.setState({
          callData: false,
          loading: false,
          showResponMes: true,
          response_test_result_status: responseJsonData.status,
          DataTestResult: responseJsonData,
          datarole: responseJsonData.roles
        });
      }
    });
  };
  onFormSubmitUpdate(e) {
    e.preventDefault(); // Stop form submit

    this.setState({ submitted: true });
    const { fileUpdate, dataDetail } = this.state;
    if (fileUpdate.length > 0) {
      const data = new FormData();

      data.append("file", fileUpdate[0]);
      data.append("id", dataDetail.id);

      PostFromData("/member-process/mqa/upload", data).then(result => {
        let responseJson = result;
        if (responseJson.status === 1) {
          this.setState({
            fileMQAadd_value: "",
            fileMQAValue: "",
            ndid_role_id: "",
            submitted: false,
            showSucess: true
          });
        } else {
          this.setState({
            submitted: false,
            showError: true
          });
        }
        this.setState({
          submitted: false,
          response_status: responseJson.status,
          response_message: responseJson.message
        });
      });
    } else {
      this.setState({
        submitted: false,
        response_status: 0,
        response_message: "Please Input "
      });
    }
  }

  onChangeAdd = e => {
    this.setState({
      ndid_role: e,
      checkedList: e
    });
  };
  onClickValue(value) {
    this.setState({
      ndid_role: value
    });
  }

  onChangeFileUpdate(e) {
    this.setState({
      fileUpdateName: e.target.files[0].name,
      fileUpdate: e.target.files
    });
  }

  fileMQAadd(e) {
    this.setState({
      fileMQAValue: e.target.files[0].name,
      fileMQAadd_value: e.target.files
    });

    // e.target.value = null;
  }

  render() {
    let content;

    let res_message = "";

    const {
      ndid_role,
      fileMQAadd_value,
      fileUpdate,
      showResponMes,
      submitted
    } = this.state;

    //Response Message
    if (showResponMes) {
      if (this.state.response_add_status === 0) {
        res_message = (
          <div className="alert alert-danger">
            {this.state.response_add_message}
          </div>
        );
      } else if (this.state.response_add_status === 1) {
        res_message = (
          <div className="alert alert-success">
            {this.state.response_add_message}
          </div>
        );
      }

      if (this.state.response_update_status === 0) {
        res_message = (
          <div className="alert alert-danger">
            {this.state.response_update_message}
          </div>
        );
      } else if (this.state.response_update_status === 1) {
        res_message = (
          <div className="alert alert-success">
            {this.state.response_update_message}
          </div>
        );
      }
    }

    let enabled = false;
    let enabledUpdate = false;
    if (submitted) {
      enabled = false;
      enabledUpdate = false;
    } else {
      enabled = ndid_role.length > 0 && fileMQAadd_value.length > 0;
      enabledUpdate = fileUpdate.length > 0;
    }

    const thTableFile = (
      <thead className="trHeaderColor">
        <tr>
          <th className="width-150">Role</th>
          <th>File Name</th>
          <th>Status</th>
          <th className="width-200">Upload File</th>
        </tr>
      </thead>
    );
    const thTableDate = (
      <thead className="trHeaderColor">
        <tr>
          <th className="width-150">Role</th>
          <th>File Name</th>
          <th>Status</th>
          <th className="width-200">Upload Date</th>
        </tr>
      </thead>
    );
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        let dataRoleAdd;
        if (this.state.datarole.length > 0) {
          dataRoleAdd = this.state.datarole.map(function(dataRole) {
            return (
              <Col span={6}>
                <Checkbox value={dataRole.id} disabled={!dataRole.enable}>
                  {dataRole.display_name}
                </Checkbox>
              </Col>
            );
          });
        }
        let dataRoleEdit;
        if (this.state.datarole.length > 0) {
          dataRoleEdit = this.state.datarole.map(
            function(dataRole) {
              return (
                <Col span={6}>
                  {this.state.dataDetail.role.indexOf(String(dataRole.id)) >
                    -1 ||
                  this.state.dataDetail.role.indexOf(dataRole.id) > -1 ? (
                    <label class="ant-checkbox-wrapper ant-checkbox-wrapper-disabled">
                      <span class="ant-checkbox ant-checkbox-checked ant-checkbox-disabled">
                        <input
                          type="checkbox"
                          class="ant-checkbox-input"
                          checked
                          value={dataRole.id}
                          disabled
                        />
                        <span class="ant-checkbox-inner" />
                      </span>
                      <span>{dataRole.display_name}</span>
                    </label>
                  ) : (
                    <label class="ant-checkbox-wrapper ant-checkbox-wrapper-disabled">
                      <span class="ant-checkbox ant-checkbox ant-checkbox-disabled">
                        <input
                          type="checkbox"
                          class="ant-checkbox-input"
                          value={dataRole.id}
                          disabled
                        />
                        <span class="ant-checkbox-inner" />
                      </span>
                      <span>{dataRole.display_name} </span>
                    </label>
                  )}
                </Col>
              );
            },

            this
          );
        }

        content = (
          <div>
            {this.state.callData === true && <LoadPage />}
            <HeaderContent message="Membership Qualification Assessment" />
            <div className="col-12 mqa">
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Overview" key="1">
                  {this.state.callData === false ? (
                    <frameElement>
                      <p>
                        <b>NDID Member Qualification Assessment (MQA)</b>
                      </p>
                      <br />
                      <p>
                        คือ การประเมิน (Assessment) มาตรการต่าง ๆ ที่หน่วยงานสมาชิกใช้ในการระบุ (Identify), ป้องกัน (Protect), ตรวจสอบ (Detect), รับมือ (Response) และ กู้คืน (Recover) ภัยหรือความเสี่ยงทางไซเบอร์  
                      </p>
                      <br />
                      <p>
                        การประเมินสมาชิกแบ่งเป็น 2 วิธี ได้แก่ (1) การตรวจประเมินโดยผู้ตรวจสอบอิสระ (Independent Audit) และ (2) การประเมินตนเองของหน่วยงานสมาชิก (Self-Assessment) โดยแต่ละสมาชิกต้องผ่านการประเมินด้วยวิธีใดวิธีหนึ่ง ตามระดับ (Tier) ของสมาชิก นอกจากนี้การประเมินยังแบ่งออกเป็น 2 ระยะ ได้แก่ (1) การประเมินก่อนได้รับอนุญาตให้เชื่อมต่อระบบกับระบบ NDID และ (2) การประเมินอย่างต่อเนื่องหลังได้รับอนุญาตให้เชื่อมต่อกับระบบ NDID
                      </p>
                      <br />
                      <p>
                        <u>กรุณาเลือกชุดเอกสารหลักเกณฑ์และหัวข้อการตรวจประเมินตามระดับสมาชิกของท่าน</u>
                        <ul>
                          <li><b>การตรวจประเมินโดยผู้ตรวจสอบอิสระ (Independent Audit) สำหรับสมาชิกระดับ 1 (Tier 1) ได้แก่ IdP / AS / Proxy</b>
                              <br />ทั้ง<u>ระยะก่อน</u>ได้รับอนุญาตให้เชื่อมต่อระบบ NDID และ<u>หลังเชื่อมต่อ</u>ระบบ NDID แล้ว (นำส่งผลการตรวจประเมินทุก 2 ปี) โดยผู้ตรวจสอบอิสระอ้างอิงหลักเกณฑ์และหัวข้อการตรวจประเมินตามที่ NDID กำหนด
                          </li>
                          <li><b>การประเมินตนเองของหน่วยงานสมาชิก (Self-Assessment) สำหรับสมาชิกระดับ 2 (Tier 2) ได้แก่ RP</b>
                              <br />ทั้ง<u>ระยะก่อน</u>ได้รับอนุญาตให้เชื่อมต่อระบบ NDID และ<u>หลังเชื่อมต่อ</u>ระบบ NDID (นำส่งผลการตรวจประเมินทุก 1 ปี) โดยผู้ประเมินต้องระบุคำตอบลงในแบบสอบถามที่ NDID จัดทำขึ้น พร้อมแนบหลักฐานประกอบตามที่กำหนด
                          </li>
                        </ul>
                      </p>
                      <br />
                      <p>
                        <u>การนำส่งผลการตรวจประเมิน</u>
                      </p>
                      <br />
                      <p>
                        กรุณานำส่งทั้ง 2 ช่องทางให้แก่ NDID ดังนี้
                        <ul>
                          <li><b>แบบ Soft File</b>
                            <br />ส่งมาที่ <a href="mailto:info@ndid.co.th">info@ndid.co.th</a>
                            <br />โปรดระบุ Email Subject : [NDID_MQA] ชื่อบริษัท_Role_ผลการตรวจประเมิน MQA ประจำปี XXXX 
                            <br />ตัวอย่าง <i>[NDID_MQA] ABC_RP_ผลการตรวจประเมิน MQA ประจำปี 2563</i>
                          </li>
                          <li>
                            <b>แบบ Hard Copy</b>
                            <br />ส่งมาที่
                            <blockquote>
                              บริษัท เนชั่นแนลดิจิทัล ไอดี จำกัด<br />
                              อาคารเมืองไทย-ภัทร คอมเพล็กซ์ ทาวเวอร์บี ชั้น 27 เลขที่ 252/125 (A-B) <br />
                              ถ.รัชดาภิเษก ห้วยขวาง กรุงเทพมหานคร 10310
                            </blockquote>
                            โปรดระบุหน้าซองเอกสาร เรื่อง: [NDID_MQA] ชื่อบริษัท_Role_ผลการตรวจประเมิน MQA ประจำปี XXXX
                            <br />ตัวอย่าง <i>[NDID_MQA] ABC_RP_ผลการตรวจประเมิน MQA ประจำปี 2563</i>
                          </li>
                          </ul>
                      </p>

                      <div className="documentBlock">
                        <p className="titleDocument">
                          เอกสารประกอบการทดสอบ Membership Qualification
                          Assessment
                        </p>
                        {this.state.callData === false && (
                          <frameElement>
                            <ul className="testDoccumentFile">
                              <OverviewFeedDocument
                                dataFeed={this.state.DataOverview.data}
                              />
                            </ul>
                          </frameElement>
                        )}
                      </div>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
                <TabPane tab="Test Result" key="2">
                  {this.state.callData === false ? (
                    <frameElement>
                      <p>
                        ในส่วนของเอกสารประกอบการทดสอบ MQA เมื่อสมาชิกรวบรวมเอกสารที่เกี่ยวข้องเรียบร้อยแล้ว ให้สมาชิกสแกนเอกสาร/หลักฐานตามรายละเอียดที่ระบุไว้ใน checklist ข้างต้น และทำการอัพโหลดไว้ที่ Member Portal ที่เมนูนี้ เพื่อให้ทาง NDID ทำการตรวจสอบในลำดับถัดไป
                      </p>
                      <br />
                      {res_message}
                      <Button
                        className="btnUpload"
                        onClick={this.showModalAddFile}
                      >
                        <i
                          className="fa fa-upload faFileUpload"
                          aria-hidden="true"
                        >
                          {" "}
                          <font>Upload File</font>
                        </i>
                      </Button>
                      <div className="textTitleLarge">ผลการทดสอบฉบับเต็ม</div>
                      <div className="app">
                        <table className="table table-striped tableSummary min-table-820">
                          {thTableFile}
                          <tbody>
                            {this.state.callData === false && (
                              <MQAFeed
                                dataFeed={this.state.DataTestResult}
                                handelDownloadFile={this.handelDownloadFile}
                                showModal={this.showModal}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="textTitleLarge">ประวัติผลการทดสอบ</div>
                      <div className="app">
                        <table className="table table-striped tableSummary min-table-820">
                          {thTableDate}
                          <tbody>
                            {this.state.callData === false && (
                              <MQAFeedHistory
                                dataFeed={this.state.DataTestResult}
                                handelDownloadFile={this.handelDownloadFile}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Modal
                        title="Upload Test Result File"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancelUpdate}
                        className="applicationModal"
                      >
                        <form
                          onSubmit={this.onFormSubmitUpdate}
                          class="block-memberapplication-form"
                          ref={el => (this.MQAUpdateFormRef = el)}
                        >
                          <div class="block-application-roles">
                            <label>
                              Roles<span className="colorRed">*</span>
                            </label>

                            <Row>
                              {this.state.datarole.length > 0 && dataRoleEdit}
                            </Row>
                          </div>

                          <div class="block-application-form">
                            <label>
                              Test Result File
                              <span className="colorRed">*</span>
                            </label>
                            <div className="form-group">
                              <div className="input-group input-file">
                                <input
                                  type="text"
                                  className="form-control input-upload-file"
                                  readOnly
                                  value={this.state.fileUpdateName}
                                />
                                <label className="input-group-btn">
                                  <span className="btn btn-upload">
                                    Upload file
                                    <input
                                      type="file"
                                      className="btn-upload-file"
                                      onChange={this.onChangeFileUpdate}
                                      accept="image/*,.pdf,.xlsx,.zip,.doc,.docx,.xls"
                                      ref={this.updateUploadRef}
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="block-btn-footer">
                            <button
                              type="reset"
                              className="btn-reset"
                              onClick={this.handleCancelUpdate}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn-submit"
                              disabled={!enabledUpdate}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Modal>

                      <Modal
                        title="Upload Test Result File"
                        visible={this.state.visibleAddFile}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        className="applicationModal"
                      >
                        <form
                          onSubmit={this.onFormSubmit}
                          class="block-memberapplication-form"
                          ref={el => (this.MQAFormRef = el)}
                        >
                          <div class="block-application-roles">
                            <label>
                              Roles<span className="colorRed">*</span>
                            </label>

                            <Checkbox.Group
                              style={{ width: "100%" }}
                              onChange={this.onChangeAdd}
                              value={this.state.ndid_role}
                            >
                              <Row>
                                {this.state.datarole.length > 0 && dataRoleAdd}
                              </Row>
                            </Checkbox.Group>
                          </div>

                          <div class="block-application-form">
                            <label>
                              Test Result File
                              <span className="colorRed">*</span>
                            </label>
                            <div className="form-group">
                              <div className="input-group input-file">
                                <input
                                  type="text"
                                  className="form-control input-upload-file"
                                  readOnly
                                  value={this.state.fileMQAValue}
                                />
                                <label className="input-group-btn">
                                  <span className="btn btn-upload">
                                    Upload file
                                    <input
                                      type="file"
                                      className="btn-upload-file"
                                      onChange={this.fileMQAadd}
                                      accept="image/*,.pdf,.xlsx,.zip,.doc,.docx,.xls"
                                      ref={this.uploadRef}
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="block-btn-footer">
                            <button
                              type="reset"
                              className="btn-reset"
                              onClick={this.handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              disabled={!enabled}
                              className="btn-submit"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Modal>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
              </Tabs>
            </div>
            <SweetAlert
              show={this.state.showSucess}
              type="success"
              title=""
              text={this.state.response_message}
              onConfirm={this.onConfirmSuccess}
            />
            <SweetAlert
              show={this.state.showError}
              type="error"
              title=""
              text={this.state.response_message}
              onConfirm={() => this.setState({ showError: false })}
            />
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}
const connectedMemberMQAPage = connect(mapStateToProps)(MemberMQAPage);
export { connectedMemberMQAPage as MemberMQAPage };
