import React from "react";
import { connect } from "react-redux";

import { Tabs } from "antd";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import "./index.css";
import { GetData } from "../../_services";
import SignOffFeedFile from "./sign-off-feed-file";
import SummaryFeed from "./summary-feed";
import { DownloadFiles } from "../../_services/data.service";
import OverviewFeedDocument from "../MemberProcessPage/Resource_feed_document";
class MemberSITPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      callData: true,
      signOffStatus: false,
      dataSignOff: {
        body: "",
        file_download_status: false,
        file_download_url: "",
        document_history: []
      }
    };

    this.callback = this.callback.bind(this);
  }
  componentDidMount() {
    CheckPermission("/process/sit").then(result => {
      this.setState({
        loading: false,
        data: result
      });
      if (result === 1) {
        GetData("/member-process/sit/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataOverview: responseJsonData.data,
              dataBody: responseJsonData.data["body"],
              dataWebsiteTest: responseJsonData.data["website_test"]
            });
          }
        });
      }
    });
  }
  callback(key) {
    this.setState({
      callData: true
    });
    if (key === "1") {
      GetData("/member-process/sit/overview").then(res => {
        let responseJsonData = res;

        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataOverview: responseJsonData.data,
            dataBody: responseJsonData.data["body"],
            dataWebsiteTest: responseJsonData.data["website_test"]
          });
        }
      });
    } else if (key === "2") {
      GetData("/member-process/sit/summary/portal").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataSummary: responseJsonData
          });
        }
      });
    } else if (key === "3") {
      GetData("/member-process/sit/sign-off").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataSignOff: responseJsonData.data,
            dataBodySignOff: responseJsonData.data.body
          });
        }
      });
    }
  }
  viewDetail = e => {
    let data = this.state.dataOverview.document[
      e.target.getAttribute("document-id")
    ];
    this.setState({
      visible: true,
      dataDetail: data
    });
  };
  viewDetailSummary = e => {
    let data = this.state.dataSummary.data[e.target.getAttribute("data-id")];
    this.setState({
      visible: true,
      dataDetailSummary: data
    });
  };
  viewDetailSignOff = e => {
    let data = this.state.dataSignOff.document_history[
      e.target.getAttribute("document_history-id")
    ];
    this.setState({
      visible: true,
      dataDetailSignOff: data
    });
  };
  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");
    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=document_public",
      name
    );
  };

  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=member_process_sign_off",
      name
    );
  };

  handelDownloadFileSignOff = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=sign_off_document",
      name
    );
  };

  render() {
    const TabPane = Tabs.TabPane;

    const thTable = (
      <thead className="trHeaderColor">
        <tr>
          <th>File Name</th>
          <th>Upload Date</th>
        </tr>
      </thead>
    );
    let content;
    let statusSignoff;

    if (this.state.dataSignOff.file_download_status === "true") {
      statusSignoff = (
        <button
          type="button"
          className="btn btn-primary"
          data-id={this.state.dataSignOff.file_download_url.filecode}
          data-value={this.state.dataSignOff.file_download_url.filename}
          onClick={this.handelDownloadFileSignOff}
        >
          Download Sign-off Document
        </button>
      );
    } else {
      statusSignoff = (
        <button type="button" className="btn btn-primary " disabled>
          Download Sign-off Document
        </button>
      );
    }
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            {this.state.callData === true && <LoadPage />}
            <HeaderContent message="SIT" />
            <div className="col-12">
              <div className="sit">
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                  <TabPane tab="Overview" key="1" animated="false">
                    {this.state.callData === false ? (
                      <div>
                        <p>
                        System Integration Testing (SIT) เป็นขั้นตอนการทดสอบการเชื่อมต่อระบบระหว่างสมาชิกกับ API ของ NDID เพื่อให้มั่นใจว่าแอปพลิเคชันของสมาชิกสามารถทำงานได้อย่างราบรื่นถูกต้องและเป็นไปตามที่คาดหวังไว้บน NDID Platform
                        </p>{" "}
                        <div />
                        <div className="mt-20">
                        การทดสอบ System Integration Testing (SIT) มีขั้นตอนดังต่อไปนี้
                        </div>
                        <ul className="list-none">
                          <li>
                            1. สมาชิกเตรียมความพร้อมสำหรับการทดสอบ SIT โดยสามารถอ่านข้อมูลเพิ่มเติมได้จากเอกสารประกอบการทดสอบ SIT ตามด้านล่าง
                          </li>
                          <li>
                            2. สมาชิกทดสอบ Test Case ที่เกี่ยวข้องกับ Role ที่สมาชิกสมัครให้ครบถ้วน ผ่านทางเว็บไซต์สำหรับทดสอบ SIT (URL ตามด้านล่าง)
                          </li>
                          <li>
                            3. หลังจากสมาชิกผ่านการทดสอบ Test Case ที่เป็น Case บังคับทั้งหมด ทาง NDID จะออกเอกสารสำหรับ Sign-off ให้กับสมาชิก
                          </li>
                          <li>4. สมาชิกลงนามเอกสารสำหรับ Sign-off</li>
                          <li>
                            5. สมาชิกจะต้องทดสอบ SIT ให้เสร็จสิ้นภายในระยะเวลาที่กำหนด นับจากเริ่มทดสอบ มิฉะนั้นจะถือว่าไม่ผ่านการทดสอบ และต้องเริ่มกระบวนการทดสอบใหม่
                          </li>
                        </ul>
                        <p />
                        <div className="documentBlock">
                          <p className="titleDocument">
                            เว็บไซต์สำหรับทดสอบ SIT
                          </p>
                          <ul className="testDoccumentFile linkI">
                            <li className="childDocument">
                              <a
                                href="https://sit.ndid.co.th"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="documentItem color2"
                              >
                                <i
                                  className="fa fa-file-text faFileText color1"
                                  aria-hidden="true"
                                />
                                https://sit.ndid.co.th
                                <i
                                  className="fa fa-external-link faExternalLink"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="textTitleLarge">
                          เอกสารประกอบการทดสอบ SIT
                        </div>
                        <div className="">
                          <ul className="testDoccumentFile">
                            {this.state.callData === false && (
                              <OverviewFeedDocument
                                dataFeed={this.state.dataOverview}
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </TabPane>

                  <TabPane tab="Summary" key="2" animated="false">
                    {this.state.callData === false ? (
                      <div className="app2">
                        <div className="tableWidth">
                          <table className="table table-striped tableSummary min-table-820">
                            <thead className="trHeaderColor">
                              <tr>
                                <th className="width-65">Role</th>
                                <th className="width-65">Status</th>
                                <th className="width-161">Start Date</th>
                                <th className="width-161">Finish Date</th>
                                <th className="width-72">Total</th>
                                <th className="width-72">Skipped</th>
                                <th className="width-60">Failed</th>
                                <th className="width-65">Passed</th>
                                <th className="width-109">Progress</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.callData === false && (
                                <SummaryFeed
                                  dataFeed={this.state.dataSummary}
                                  viewDetail={this.viewDetailSummary}
                                />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </TabPane>

                  <TabPane tab="Sign-Off" key="3" animated="false">
                    {this.state.callData === false ? (
                      <div>
                        <ul className="p-5 list-none">
                          <li>
                            เมื่อสมาชิกผ่านการทดสอบระบบ SIT เรียบร้อยแล้ว 
                            <ul className="list-none color-text-description">
                              <li>1.	ดาวน์โหลดแบบฟอร์ม SIT Sign-Off ตาม link ด้านล่าง</li>
                              <li>2.	พิมพ์แบบฟอร์ม SIT Sign-Off และให้ผู้มีอำนาจลงนาม </li>
                              <li>3.	สแกนแบบฟอร์ม SIT Sign-Off ที่ลงนามเรียบร้อยแล้ว และส่งอีเมล์มาที่ <a href="mailto:it_support_round2@ndid.co.th">it_support_round2@ndid.co.th</a> (ซึ่งในอนาคตสมาชิกสามารถอัพโหลดในระบบ Member Portal เองได้) เพื่อเป็นหลักฐานยืนยันการผ่านการทดสอบระบบของสมาชิก</li>
                            </ul>
                          </li>
                        </ul>

                        {statusSignoff}
                        <p />
                        <div className="textTitleLarge">
                          ประวัติการ Sign-off
                        </div>
                        <table className="table table-striped tableSummary">
                          {thTable}
                          <tbody>
                            {this.state.callData === false && (
                              <SignOffFeedFile
                                dataFeed={this.state.dataSignOff}
                                viewDetail={this.viewDetailSignOff}
                                handelDownloadFile={this.handelDownloadFile}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div />
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div className="col-12">{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}
const connectedMemberSITPage = connect(mapStateToProps)(MemberSITPage);
export { connectedMemberSITPage as MemberSITPage };
