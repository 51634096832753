import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
import FeedRoleOne from "../../_components/FeedRole/feedroleone";
class SummaryFeed extends Component {
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.data.length > 0) {
      dataFeed = this.props.dataFeed.data.map(
        function(feedData, index) {
          return (
            <tr key={index}>
              <td>
                <FeedRoleOne data={feedData} />
              </td>
              <td>{feedData.mode}</td>
              <td style={{textTransform: 'capitalize'}}>{feedData.status}</td>
              <td className="white-space-nowarp">{feedData.start_date}</td>
              <td className="white-space-nowarp">{feedData.finish_date}</td>
              <td>{feedData.pair_with_abbreviation || '-'}</td>
              <td>{feedData.skipped_case || '-'}</td>
              <td>{feedData.failed_case || '-'}</td>
              <td>{feedData.passed_case || '-'}</td>
              <td>{feedData.progress}</td>
            </tr>
          );
        },

        this
      );
    } else {
      dataFeed = <RecordNotFound cols="10" />;
    }

    return dataFeed;
  }
}

export default SummaryFeed;
