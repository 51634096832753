import { authHeader } from "../_helpers";

const BaseURL = process.env.REACT_APP_API_URL || '/api';

export function PostData(type, userData) {
  return new Promise((resolve, reject) => {
    let user = JSON.parse(localStorage.getItem("user"));

    fetch(BaseURL + type, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: user ? user.userData.token : '',
      },
      body: JSON.stringify(userData)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function PutData(type, data) {
  return new Promise((resolve, reject) => {
    let user = JSON.parse(localStorage.getItem("user"));

    fetch(BaseURL + type, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: user.userData.token
      },

      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function GetData(type, data) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + type, {
      headers: authHeader(),
      params: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function PostFromData(type, data) {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: data
    };

    fetch(BaseURL + type, requestOptions)
      .then(response => response.json())
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const downloading = {};

export function DownloadFiles(type, name) {
  if (downloading[type]) return;
  downloading[type] = true;
  return new Promise((resolve, reject) => {
    fetch(BaseURL + type, {
      headers: authHeader()
    })
      .then(stream => new Response(stream.body))
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        delete downloading[type];
      })
      .catch(err => {
        console.error(err);
        delete downloading[type];
      });
  });
}

export function getImage(type) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + type, {
      headers: authHeader()
    })
      .then(stream => new Response(stream.body))
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        resolve(url);
      })
      .catch(err => console.error(err));
  });
}
