import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
import { DownloadFiles } from "../../_services/data.service";
import FeedRoleMulti from "../../_components/FeedRole/feedrolemulti";

const handelDownloadFile = e => {
  let id = e.target.getAttribute("data-id");
  let name = e.target.getAttribute("data-value");

  DownloadFiles(
    "/file?filecode=" +
      id +
      "&type=member_process_result",
    name
  );
};

class NFTFeed extends Component {
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.data.length > 0) {
      dataFeed = this.props.dataFeed.data.map(function(feedData, index) {
        return (
          <tr key={index}>
            <td>
              <a
                href
                data-id={feedData.filecode}
                data-value={feedData.filename}
                onClick={handelDownloadFile}
              >
                {feedData.filename}
              </a>
            </td>
            <td className="text-center">
              <FeedRoleMulti data={feedData.role} />
            </td>
            <td className="text-center" style={{textTransform: 'capitalize'}}>
              <font className="textUploadDate">{feedData.status}</font>
            </td>
            <td className="text-center">
              <font className="textUploadDate">{feedData.upload_date}</font>
            </td>
          </tr>
        );
      });
    } else {
      dataFeed = <RecordNotFound cols="4" />;
    }
    return dataFeed;
  }
}
export default NFTFeed;
