import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./sidebar.css";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.pathname,
      data: 1,
      loading: true,
      activeSidebar: "active",
      dataRoles: {
        roles: {
          ndid_administrator: 0,
          it_administrator: 0,
          tester: 0,
          business: 0,
          settlement: 0
        }
      }
    };
  }
  componentDidMount() {}

  componentWillUnmount() {}
  isAative = (currentpath, path, id) => {
    let path_home = window.location.pathname;
    if (
      (id === "news" && path_home === "/home") ||
      (id === "news" && path_home === "/")
    ) {
      return "childSidebarMenu active";
    } else if (currentpath === path) {
      return "childSidebarMenu active";
    } else {
      return "childSidebarMenu";
    }
  };
  _handleClickBtn() {
    let w = window.innerWidth;

    if (w > 991) {
      this.setState({
        activeSidebar: "active"
      });
    } else {
      if (this.state.activeSidebar === "active") {
        this.setState({
          activeSidebar: "hide"
        });
      } else if (this.state.activeSidebar === "hide") {
        this.setState({
          activeSidebar: "active"
        });
      } else {
        this.setState({
          activeSidebar: "hide"
        });
      }
    }
  }
  render() {
    let path = window.location.pathname;

    const { authentication, permission } = this.props;
    let content_slidebar = "";
    if (permission.memu_visible !== undefined) {
      const userDetail = authentication.user.userData;
      const dataRoles = userDetail;
      let sidebarListItem;
      if (
        path === "/application-submission" ||
        path === "/application-status" ||
        path === "/membership-contract"
      ) {
        if (
          dataRoles.roles.ndid_administrator === 1 &&
          permission.memu_visible.application_status === true
        ) {
          sidebarListItem = [
            {
              name: "Application Status",
              path: "/application-status",
              id: "application-status"
            }
          ];
        } else if (permission.memu_visible.application_status === false) {
          sidebarListItem = [
            {
              name: "Application Submission",
              path: "/application-submission",
              id: "application-submission"
            }
          ];
        } else if (permission.memu_visible.process === false) {
          sidebarListItem = [
            {
              name: "Application Submission",
              path: "/application-submission",
              id: "application-submission"
            },
            {
              name: "Application Status",
              path: "/application-status",
              id: "application-status"
            }
          ];
        } else {
          sidebarListItem = [
            {
              name: "Application Submission",
              path: "/application-submission",
              id: "application-submission"
            },
            {
              name: "Application Status",
              path: "/application-status",
              id: "application-status"
            },
            {
              name: "Membership Contract",
              path: "/membership-contract",
              id: "member-contract"
            }
          ];
        }
      } else if (
        path === "/process" ||
        path === "/process/sit" ||
        path === "/process/uat" ||
        path === "/process/application-review" ||
        path === "/process/nft" ||
        path === "/process/mqa"
      ) {
        sidebarListItem = [
          { name: "Overview", path: "/process", id: "process-overview" },
          { name: "SIT", path: "/process/sit", id: "process-sit" },
          { name: "UAT", path: "/process/uat", id: "process-uat" },
          {
            name: "Application Review",
            path: "/process/application-review",
            id: "process-review"
          },
          { name: "NFT", path: "/process/nft", id: "process-nft" },
          {
            name: "Membership Qualification Assessment",
            path: "/process/mqa",
            id: "process-mqa"
          }
        ];
      } else {
        sidebarListItem = [
          { name: "News", path: "/news", id: "news" },
          { name: "Resources", path: "/resource", id: "resource" },
          { name: "About us", path: "/aboutus", id: "about" },
          { name: "Contact us", path: "/contact", id: "contact" },
          { name: "FAQs", path: "/faq", id: "faq" }
        ];
      }

      const sidebarList = sidebarListItem.map((item, index) => (
        <li
          key={index}
          className={this.isAative(path, item.path, item.id)}
          id={item.id}
          onClick={this._handleClickBtn.bind(this)}
        >
          <Link to={item.path} className="linkSidebarMenu">
            {" "}
            {item.name}
          </Link>
        </li>
      ));

      content_slidebar = (
        <aside className="col-lg-3 col-md-3 col-xs-12">
          <section className="sidebar">
            <button
              type="button"
              id="sidebarCollapse"
              className="btn btn-info btnEvent"
              onClick={this._handleClickBtn.bind(this)}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <nav id="sidebar" className={this.state.activeSidebar}>
              <div className="sidebar-header" />
              <ul className="sidebarMenu">{sidebarList}</ul>
            </nav>
          </section>
        </aside>
      );
    }

    return <div>{content_slidebar}</div>;
  }
}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  return {
    alert,
    authentication
  };
}

const connectedApp = connect(mapStateToProps)(Sidebar);
export { connectedApp as Sidebar };
