import React, { Component } from "react";

import "./dropdown.css";

class DropdownMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: this.props.title,
      timeOut: null,
      dataSelected: []
    };
    this.close = this.close.bind(this);
    this.OnSubmitSearch = this.OnSubmitSearch.bind(this);
  }

  componentDidUpdate() {
    const { listOpen } = this.state;
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);
  }
  OnSubmitSearch = () => {
    this.close();
    this.props.OnSubmit();
  };

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  close(timeOut) {
    this.setState({
      listOpen: false
    });
  }

  static getDerivedStateFromProps(nextProps) {
    const count = nextProps.list
      .filter(a => a.selected === "true")
      .map(function(item, index) {
        return item.name;
      });

    if (count.length === 0) {
      return { headerTitle: nextProps.title };
    } else if (count.length > 0 && count.length <= 2) {
      return { headerTitle: `${count.join(", ")}` };
    } else if (count.length > 2) {
      return { headerTitle: `${count.length} Categories` };
    }
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }

  render() {
    const {
      list,
      toggleItem,

      toggleSelectedAll,
      toggleClearAll
    } = this.props;
    const { listOpen, headerTitle } = this.state;
    return (
      <div className="dd-wrapper">
        <div className="dd-header" onClick={() => this.toggleList()}>
          <div className="dd-header-title">{headerTitle}</div>
          {listOpen ? (
            <i className="fa fa-angle-up" aria-hidden="true" />
          ) : (
            <i className="fa fa-angle-down" aria-hidden="true" />
          )}
        </div>
        {listOpen && (
          <div>
            <ul className="dd-list" onClick={e => e.stopPropagation()}>
              <span onClick={toggleSelectedAll}>Select All</span>
              <span onClick={toggleClearAll} className="text-right">
                Clear
              </span>
              <div className="dd-list-body">
                {list.map((item, index) => (
                  <li
                    className="dd-list-item"
                    key={item.name}
                    onClick={() => toggleItem(index, item.id, item.key)}
                  >
                    {item.name}{" "}
                    {item.selected === "true" && (
                      <i className="fa fa-check-circle" aria-hidden="true" />
                    )}
                  </li>
                ))}
              </div>
              <hr />
              <button
                className="btn-category btn-search"
                onClick={this.OnSubmitSearch}
              >
                {" "}
                Submit
              </button>
              <button
                className="btn-category btn-cancel"
                onClick={() => this.toggleList()}
              >
                {" "}
                Cancel
              </button>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default DropdownMultiple;
