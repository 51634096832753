import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { GetData } from "../../_services";
import { Pagination } from "antd";
import "./index.css";
import { HeaderContent, NotPermission } from "../../_components";
import Carousel from "nuka-carousel";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import NewsListShow from "./NewsListShow";
import DropdownMultiple from "../../_components/dropdown";

class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      show_per_page: 9,
      total_page: 1,
      current_page: 1,
      slidesToAutoplay: true,
      slidesToShow: 1,
      wrapAround: false,
      underlineHeader: false,
      cellAlign: "left",
      transitionMode: "scroll",
      heightMode: "max",
      withoutControls: false,
      slideIndex: 0,
      slideSpeed: 15000,
      dataCategory: []
    };
    this.onChangePage = this.onChangePage.bind(this);
    this.callbackPage = this.callbackPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.OnSubmit = this.OnSubmit.bind(this);
  }
  toggleSelected = (index, id, key) => {
    let temp = JSON.parse(JSON.stringify(this.state[key]));

    if (temp[index].selected === "true") {
      temp[index].selected = "false";
    } else {
      temp[index].selected = "true";
    }

    this.setState({
      [key]: temp
    });
  };

  toggleSelectedAll = () => {
    let temp = JSON.parse(JSON.stringify(this.state.dataCategory));
    let data = temp.map(function(item, index) {
      item.selected = "true";
      return item;
    });

    this.setState({
      dataCategory: data
    });
  };

  toggleClearAll = () => {
    let temp = JSON.parse(JSON.stringify(this.state.dataCategory));
    let data = temp.map(function(item, index) {
      item.selected = "false";
      return item;
    });

    this.setState({
      dataCategory: data
    });
  };

  OnSubmit() {
    const list = this.state.dataCategory
      .filter(a => a.selected === "true")
      .map(function(item) {
        return item.id;
      });
    const value = list.join(", ");

    this.callbackPage(2, value);
  }

  componentDidMount() {
    CheckPermission("/news").then(result => {
      this.setState({
        data: result
      });

      if (result === 1) {
        GetData("/news").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataRows: responseJsonData.data.row,
              dataCategory: responseJsonData.data.category,
              dataPinned: responseJsonData.data.pinned,
              dataTotalPage: responseJsonData.data.total_page,
              dataTotalRecord: responseJsonData.data.total_record
            });
          }
        });
      }
    });
  }

  callbackPage(key, page) {
    this.setState({
      callData: true,
      show_res_message: false
    });
    if (key === 1) {
      GetData("/news?page=" + page).then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            current_method: 1,
            visible: false,
            dataRows: responseJsonData.data.row,
            dataPinned: responseJsonData.data.pinned,
            dataTotalPage: responseJsonData.data.total_page,
            dataTotalRecord: responseJsonData.data.total_record
          });
        }
      });
    } else if (key === 2) {
      GetData("/news?category=" + page).then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            current_method: 1,
            visible: false,
            dataRows: responseJsonData.data.row,

            dataPinned: responseJsonData.data.pinned,
            dataTotalPage: responseJsonData.data.total_page,
            dataTotalRecord: responseJsonData.data.total_record
          });
        }
      });
    }
  }
  handleChange(value) {
    this.callbackPage(2, value);
  }
  onChangePage(pageNumber) {
    this.setState({ current_page: pageNumber });
    this.callbackPage(1, pageNumber);
  }
  viewDetailNews = e => {};

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        const arrayDataPinned = this.state.dataPinned;
        const listNewsCover = arrayDataPinned.map((item, index) => (
          <div key={index}>
            <Link
              to={"/news/" + item.id}
              data-id={item.id}
              data-value={item.filecode}
              onClick={this.viewDetailNews}
            >
              <img
                src={
                  (process.env.REACT_APP_API_URL || '/api') +
                  "/file-public?type=news&filecode=" +
                  item.cover_image.filecode
                }
                className="thumbnail"
                alt={`Slide ${index + 1}`}
                style={{
                  height:
                    this.state.heightMode === "current"
                      ? 100 * (index + 1)
                      : 300
                }}
              />
            </Link>
          </div>
        ));

        content = (
          <div className="news-page">
            <HeaderContent message="News" />
            {this.state.callData === true && <LoadPage />}
            <div className="row row-offcanvas row-offcanvas-right">
              <div className="col-xs-12 col-sm-12">
                <div className="carouselNews">
                  <Carousel
                    slideIndex={this.state.slideIndex}
                    afterSlide={slideIndex => this.setState({ slideIndex })}
                    autoplay={this.state.slidesToAutoplay}
                    slidesToShow={this.state.slidesToShow}
                    autoplayInterval={this.state.slideSpeed}
                    withoutControls={this.state.withoutControls}
                    transitionMode={this.state.transitionMode}
                    cellAlign={this.state.cellAlign}
                    wrapAround={this.state.wrapAround}
                    heightMode={this.state.heightMode}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <div className="arrow" onClick={previousSlide}>
                        <i className="fa fa-chevron-left" aria-hidden="true" />
                      </div>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <div className="arrow" onClick={nextSlide}>
                        <i className="fa fa-chevron-right" aria-hidden="true" />
                      </div>
                    )}
                  >
                    {listNewsCover}
                  </Carousel>
                </div>
                <div className="row news-margin select-category">
                  <div className="col-xs-12 col-lg-12">
                    <div className="pull-left">
                      <DropdownMultiple
                        titleHelper="category"
                        title="Choose Category"
                        list={this.state.dataCategory}
                        toggleItem={this.toggleSelected}
                        OnSubmit={this.OnSubmit}
                        toggleSelectedAll={this.toggleSelectedAll}
                        toggleClearAll={this.toggleClearAll}
                      />
                    </div>
                  </div>
                </div>
                <div className="row news-margin ">
                  {this.state.callData === false && (
                    <NewsListShow dataFeed={this.state.dataRows} />
                  )}
                </div>
                <div className="t20 text-right">
                  <Pagination
                    current={this.state.current_page}
                    defaultPageSize={this.state.show_per_page}
                    total={this.state.dataTotalPage}
                    onChange={this.onChangePage}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }

    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users
  };
}

const connectedNewsPage = connect(mapStateToProps)(NewsPage);
export { connectedNewsPage as NewsPage };
