import React from "react";
import "./loadding.css";
// First way to import
class LoadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  render() {
    return <div className="loading">Loading&#8230;</div>;
  }
}

export default LoadPage;
