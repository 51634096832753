import React from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./index.css";
import { getImage, GetData } from "../../_services";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";

import createDOMPurify from 'dompurify';
import { JSDOM } from 'jsdom';

const window = (new JSDOM('')).window;
const DOMPurify = createDOMPurify(window);

class NewsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      category_name: "",
      data_isset: false,
      redirect: false
    };
  }
  async componentDidMount() {
    try {
      const news_id = this.props.match.params.id;
      const gotPermission = await CheckPermission("/news");
      this.setState({
        data: gotPermission,
      });

      if (gotPermission !== 1) {
        return;
      }

      const responseJsonData = await GetData("/news/view/" + news_id);
      if (responseJsonData.status === 1) {
        this.setState({
          loading: false,
          data_isset: true,
          callData: false,
          preview_image: responseJsonData.data.cover_image.filecode,
          published_date: responseJsonData.data.published_date,
          title: responseJsonData.data.title,
          detail: responseJsonData.data.detail,
          category_name: responseJsonData.data.category_name
        });
        if (this.state.preview_image) {
          getImage(
            "/file?filecode=" + this.state.preview_image + "&type=news"
          ).then(image => {
            this.setState({
              image_url: image
            });
          });
        }
      } else {
        throw new Error('Not found news');
      }
    } catch (err) {
      this.props.history.push((process.env.PUBLIC_URL || '') + '/news');
    }
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/news" />;
    }
  };

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        if (this.state.data_isset === false) {
          content = this.renderRedirect();
        } else {
          content = (
            <div className="news-page news-detail">
              <HeaderContent message={"News: " + this.state.title} />
              {this.state.callData === true && <LoadPage />}
              <div className="row row-offcanvas row-offcanvas-right">
                <div className="cover-image col-xs-12 col-sm-12">
                  <img
                    src={this.state.image_url}
                    className="thumbnail"
                    alt=""
                  />
                </div>

                <div className="col-xs-12 col-sm-12">
                  <p className="dateFrom">
                    <span className="date">{this.state.published_date}</span>
                    <span className="from">{this.state.category_name}</span>
                  </p>
                  <h2 className="color-primary">{this.state.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.detail) }}
                  />
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      content = <NotPermission />;
    }

    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users
  };
}

const connectedNewsDetail = connect(mapStateToProps)(NewsDetail);
export { connectedNewsDetail as NewsDetail };
