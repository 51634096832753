import React, { Component } from "react";
class ApplicationFeedFile extends Component {
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.length > 0) {
      dataFeed = this.props.dataFeed.map(function(feedData, index) {
        return (
          <li>
            <a
              href
              data-id={feedData.filecode}
              data-value={feedData.filename}
              onClick={this.props.handelDownloadFile}
            >
              <i className="fa fa-download" /> {feedData.filename}
            </a>
          </li>
        );
      }, this);
    } else {
      dataFeed = "-";
    }

    return dataFeed;
  }
}

export default ApplicationFeedFile;
