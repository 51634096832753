import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
import FeedRoleCheckbox from "../../_components/FeedRole/feedrolecheckbox";
class MembershipContractFeed extends Component {
  eShow = e => {
    alert(e.target.getAttribute("data-id"));
  };
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.data.row.length > 0) {
      dataFeed = this.props.dataFeed.data.row.map(
        function(feedData, index) {
          return (
            <tr
              className="text-center"
              onClick={this.props.viewDetail}
              data={feedData.id}
            >
              <FeedRoleCheckbox
                data={feedData.ndid_role_id}
                roleAll={this.props.dataFeed.data.header}
                dataIndex={index}
              />

              <td data-id={index} className="width-160 white-space-nowarp">
                <div data={feedData.id} data-id={index}>
                  {feedData.created_at}
                </div>
              </td>
              <td data-id={index}>
                <button
                  className="buttonSubmit"
                  onClick={this.props.viewDetail}
                  data={feedData.id}
                  data-id={index}
                >
                  <i className="fa fa-eye" data-id={index} />
                  View
                </button>
              </td>
            </tr>
          );
        },

        this
      );
    } else {
      let cntcol = this.props.dataFeed.data.header.length + 2;
      dataFeed = <RecordNotFound cols={cntcol} />;
    }

    return dataFeed;
  }
}

export default MembershipContractFeed;
