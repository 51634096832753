import React, { Component } from "react";
import { connect } from "react-redux";

class NotPermission extends Component {
  render() {
    return (
      <div className="row small-up-2 medium-up-3 large-up-4" id="Body">
        <div className="medium-12 columns alert alert-danger">
          <h2>403 Access Forbidden</h2>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  return {
    alert,
    authentication
  };
}

const connectedApp = connect(mapStateToProps)(NotPermission);
export { connectedApp as NotPermission };
