import React, { Component } from "react";
import { GetData } from "../../_services/";

class FeedRoleMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      callData: true
    };
  }
  componentDidMount() {
    GetData("/ndid-role").then(res => {
      let responseJsonData = res;
      if (responseJsonData.status === 1) {
        this.setState({
          callData: false,
          data: responseJsonData.data
        });
      }
    });
  }
  render() {
    if (this.state.data.length > 0 && this.state.callData === false) {
      return this.state.data.map(
        function(feedData, index) {
          return (
            <span key={index}>
              {(this.props.data.indexOf(String(feedData.id)) > -1 ||
                this.props.data.indexOf(feedData.id) > -1) && (
                <font className={"rolesItems label-" + feedData.name}>
                  <i className="fa fa-user-o" aria-hidden="true" />{" "}
                  {feedData.display_name}
                </font>
              )}
            </span>
          );
        },

        this
      );
    } else {
      return "";
    }
  }
}

export default FeedRoleMulti;
