import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
class ResourceFeed extends Component {
  render() {
    let dataFeed = "";

    if (this.props.dataFeed.length > 0) {
      dataFeed = this.props.dataFeed.map(
        function(feedData, index) {
          return (
            <a
              href
              rel="noopener noreferrer"
              data-id={feedData.filecode}
              data-name={this.props.folderName}
              data-value={feedData.filename}
              onClick={this.props.handelDownload}
            >
              <div className="cardBox">
                <div className="itemCardBox">
                  <i
                    className="fa fa-file-text faFileText"
                    aria-hidden="true"
                  />
                  <font className="box-name">{feedData.filename}</font>
                  <i
                    className="fa fa-download faFileDownload"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </a>
          );
        },

        this
      );
    } else {
      dataFeed = <RecordNotFound cols="3" />;
    }

    return dataFeed;
  }
}

export default ResourceFeed;
