import React, { Component } from "react";
import { DownloadFiles } from "../../_services/data.service";
class OverviewFeedDocument extends Component {
  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles("/file?filecode=" + id + "&type=overview", name);
  };
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.document.length > 0) {
      dataFeed = this.props.dataFeed.document.map(
        function(feedData, index) {
          return (
            <li className="childDocument">
              <a
                href
                data-id={feedData.filecode}
                data-value={feedData.filename}
                onClick={this.handelDownloadFile}
                className="documentItem color2"
              >
                <i
                  data-id={feedData.filecode}
                  data-value={feedData.filename}
                  className="fa fa-file-text faFileText color1"
                  aria-hidden="true"
                />
                {feedData.filename}
              </a>
            </li>
          );
        },

        this
      );
    } else {
      dataFeed = <li className="childDocument">Record not found.</li>;
    }

    return dataFeed;
  }
}

export default OverviewFeedDocument;
