import React, { Component } from "react";

class FeedRoleCheckbox extends Component {
  render() {
    if (this.props.roleAll.length > 0) {
      return this.props.roleAll.map(
        function(feedData) {
          return (
            <td data-id={this.props.dataIndex}>
              {(this.props.data.indexOf(String(feedData.id)) > -1 ||
                this.props.data.indexOf(feedData.id) > -1) && (
                <div data-id={this.props.dataIndex}>
                  <i
                    className="fa fa-check colorGreen "
                    aria-hidden="true"
                    data-id={this.props.dataIndex}
                  />
                </div>
              )}
            </td>
          );
        },

        this
      );
    } else {
      return "";
    }
  }
}

export default FeedRoleCheckbox;
