import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";

import { Redirect } from "react-router-dom";

class LogoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true
    };

    // reset login status
    this.props.dispatch(userActions.logout());
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
  };

  render() {
    return <div>{this.renderRedirect()}</div>;
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}

const connectedLogoutPage = connect(mapStateToProps)(LogoutPage);
export { connectedLogoutPage as LogoutPage };
