import React from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import "./index.css";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { GetData } from "../../_services/";
import ReviewFeed from "./Application-feed";
import { DownloadFiles } from "../../_services/data.service";
import OverviewFeedDocument from "../MemberProcessPage/Resource_feed_document";

const TabPane = Tabs.TabPane;

class ProcessApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      dataReview: "",
      dataOverview: "",
      callData: true
    };
    this.callback = this.callback.bind(this);
  }

  callback(key) {
    this.setState({
      callData: true
    });
    if (key === "1") {
      GetData("/member-process/application-review/overview").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataOverview: responseJsonData
          });
        }
      });
    } else if (key === "2") {
      GetData("/member-process/application-review/summary").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataReview: responseJsonData
          });
        }
      });
    }
  }
  componentDidMount() {
    CheckPermission("/process/application-review").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/member-process/application-review/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataOverview: responseJsonData
            });
          }
        });
      }
    });
  }
  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");
    DownloadFiles("/file?filecode=" + id + "&type=document_public", name);
  };

  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=member_process_result",
      name
    );
  };

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            {this.state.callData === true && <LoadPage />}
            <HeaderContent message="Application Review" />
            <div className="uatBlock">
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Overview" key="1" className="titleTabs">
                  {this.state.callData === false ? (
                    <frameElement>
                      <div className="mt-20">
                        การทดสอบ Application Review
                        มีเงื่อนไขและขั้นตอนดังต่อไปนี้
                      </div>

                      <ul className="list-none">
                        <li>
                        1.	สมาชิกผ่านการทดสอบ UAT และลงนาม Sign-off Document เรียบร้อยแล้ว 
                        </li>
                        <li>
                        2.	สมาชิกสามารถดาวน์โหลดรายการทดสอบ (Checklist) และเกณฑ์ในการตรวจสอบได้ตาม link ด้านล่าง 
                        </li>
                        <li>
                        3.	NDID จะนัดหมายวันและเวลาที่ทำการทดสอบ Application Review กับทางสมาชิก 
                        </li>
                        <li>
                        4.	NDID จะทดสอบแอปพลิเคชันของสมาชิกตามวันและเวลาที่นัดหมายไว้ 
                        </li>
                        <li>
                        5.	หากผลการทดสอบผ่านเรียบร้อย สมาชิกพร้อมลงนามเอกสาร Sign-Off 
                        </li>
                        <li>6.	NDID อัพโหลดผลการทดสอบและเอกสาร Sign-Off เพื่อเป็นหลักฐานยืนยันการผ่านการทดสอบของสมาชิก</li>
                      </ul>

                      <div className="documentBlock">
                        <p className="titleDocument">
                          เอกสารประกอบการทดสอบ Application Review
                        </p>
                        <ul className="testDoccumentFile">
                          {this.state.callData === false && (
                            <OverviewFeedDocument
                              dataFeed={this.state.dataOverview.data}
                            />
                          )}
                        </ul>
                      </div>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
                <TabPane tab="Summary" key="2">
                  {this.state.callData === false ? (
                    <frameElement>
                      <p className="titleDocument">
                        ผลการทดสอบ Application Review
                      </p>
                      <table className="tableSummary table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">File Name</th>
                            <th className="width-15p text-center" scope="col">
                              Role
                            </th>
                            <th className="width-15p text-center" scope="col">
                              Status
                            </th>
                            <th className="width-15p text-center" scope="col">
                              Upload Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.callData === false && (
                            <ReviewFeed
                              dataFeed={this.state.dataReview}
                              handelDownloadFile={this.handelDownloadFile}
                            />
                          )}
                        </tbody>
                      </table>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedProcessApplication = connect(mapStateToProps)(
  ProcessApplication
);
export { connectedProcessApplication as ProcessApplication };
