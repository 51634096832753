import React, { Component } from "react";
import { GetData } from "../../_services/";
class FeedRoleOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      callData: true
    };
  }
  componentDidMount() {
    GetData("/ndid-role").then(res => {
      let responseJsonData = res;
      if (responseJsonData.status === 1) {
        this.setState({
          callData: false,
          data: responseJsonData.data.concat({id: 0, name: 'any', display_name: 'any'})
        });
      }
    });
  }
  render() {
    if (this.state.data.length > 0 && this.state.callData === false) {
      return this.state.data.map(
        function(feedData) {
          return (
            <span>
              {this.props.data.role === String(feedData.id) && (
                <font className={"rolesItems label-" + feedData.name}>
                  <i className="fa fa-user-o" aria-hidden="true" />{" "}
                  {feedData.display_name}
                </font>
              )}
            </span>
          );
        },

        this
      );
    } else {
      return "";
    }
  }
}

export default FeedRoleOne;
