import React from "react";
import logo from "./NDID_logo_transparent.png";
import { PostData } from "../../_services";
import { Link } from 'react-router-dom';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      newPasswordConfirm: '',
      submitting: false,
      submitted: false,
      mismatch: false,
      error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ 
      [name]: value,
      mismatch: false,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      this.setState({ mismatch: true });
      return;
    }

    this.setState({ submitting: true, error: false, errorMessage: undefined });

    try {
      const result = await PostData('/users/password/reset', {
        user_id: decodeURIComponent(this.props.match.params.id),
        token: decodeURIComponent(this.props.match.params.token),
        password: this.state.newPassword,
      });

      this.setState({
        submitting: false,
        submitted: result.status === 1,
        error: result.status !== 1,
        errorMessage: result.message,
      });
    } catch (err) {
      this.setState({
        submitting: false,
        submitted: false,
        error: true,
      });
    }
  }

  resetPasswordForm() {
    const { newPassword, newPasswordConfirm, submitting, mismatch, error, errorMessage } = this.state;
    return (
      <form name="form" onSubmit={this.handleSubmit}>
        <div
          className={
            "form-group" + (error ? " has-error" : "")
          }
        >
          {error && (
              <div className="help-block">
                {errorMessage ? 'Error: ' + errorMessage: "Something went wrong. Please try again later."}
              </div>
          )}
        </div>
        <div
          className={
            "form-group" + (mismatch ? " has-error" : "")
          }
        >
          <label htmlFor="password">New Password</label>
          <div className="inputUsername">
            <i className="fa fa-lock" aria-hidden="true"/>
          </div>
          <input
            type="password"
            className="inputField form-control"
            name="newPassword"
            value={newPassword}
            placeholder="Type your password"
            onChange={this.handleChange}
            required
          />
        </div>
        <div
          className={
            "form-group" + (mismatch ? " has-error" : "")
          }
        >
          <label htmlFor="password">Confirm New Password</label>
          <div className="inputUsername">
            <i className="fa fa-lock" aria-hidden="true"/>
          </div>
          <input
            type="password"
            className="inputField form-control"
            name="newPasswordConfirm"
            value={newPasswordConfirm}
            placeholder="Confirm your password"
            onChange={this.handleChange}
            required
          />
          {mismatch && (
            <div className="help-block">Password mismatch</div>
          )}
        </div>
        <br />
        <div className="form-group">
          <button className="btn buttonLogin">Set New Password</button>
          {submitting && (
            <img
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              alt=""
              style={{width: 'auto'}}
            />
          )}
        </div>
      </form>
    );
  }

  afterSubmittedDiv() {
    return (
      <div className="text-center">
        <h4>
          Your password has been reset.
          <br /><br />
          Please login into your account with new password <Link to={`${(process.env.PUBLIC_URL || '')}/login`}>here</Link>.
          <br /><br />
        </h4>
      </div>
    )
  }

  render() {
    const { submitted } = this.state;

    return (
      <div className="siteLogin login">
        <div className="blockLogin">
          <Link to={(process.env.PUBLIC_URL || '')}>
            <img
              src={logo}
              alt="NDID Digital Identitfy for All"
              className="logo"
            />
          </Link>

          {submitted ? 
            this.afterSubmittedDiv() : 
            this.resetPasswordForm()
          }
        </div>
      </div>
    );
  }
}

export { ResetPasswordPage };
