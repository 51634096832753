import React from "react";
import "./application.css";
import "./application-modal.less";
import { GetData, PutData } from "../../_services/";
import { DownloadFiles } from "../../_services/data.service";
import ApplicationFeed from "./application-status-feed";
import ApplicationFeedFile from "./application-status-feed-file";
import { Modal } from "antd";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { Tabs } from "antd";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import FeedRoleMulti from "../../_components/FeedRole/feedrolemulti";

const SweetAlert = withSwalInstance(swal);

const TabPane = Tabs.TabPane;

class ApplicationStatusPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      dataApproved: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      dataPending: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      dataNeedReview: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      dataCancelled: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      dataDeclined: {
        data: {
          role_proxy: false,
          header: []
        }
      },
      callData: true,
      visibleCalcel: false,
      calcelValue: "",
      dataPendingIndex: "",
      approved_record: 0,
      pending_record: 0,
      need_review_record: 0,
      cancelled_record: 0,
      rejected_record: 0,
      show_res_message: false,

      showSucess: false,
      showError: false,

      dataDetail: {
        application_submission_id: "",
        organization_id: "",
        application_source_id: "",
        ndid_role_id: [],
        ndid_role_name: [],
        status: "",
        remark: null,
        approved_date: "",
        approved_by: null,
        approved_by_name: '',
        active: "",
        created_at: "",
        application_file: {
          filecode: "",
          filename: ""
        },
        document_file: []
      }
    };
    this.callback = this.callback.bind(this);
    this.handelDownloadFile = this.handelDownloadFile.bind(this);
    this.handleSubmitCalcel = this.handleSubmitCalcel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeDataPending = this.removeDataPending.bind(this);
    this.onConfirmSuccess = this.onConfirmSuccess.bind(this);
  }

  state = { visible: false };
  componentDidMount() {
    CheckPermission("/application-status").then(result => {
      this.setState({
        loading: false,
        data: result
      });
      if (result === 1) {
        GetData("/application-status?status=approved").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              callData: false,
              dataApproved: responseJsonData,
              approved_record: responseJsonData.data.total_record.approved,
              pending_record: responseJsonData.data.total_record.pending,
              need_review_record:
                responseJsonData.data.total_record.need_review,
              cancelled_record: responseJsonData.data.total_record.cancelled,
              rejected_record: responseJsonData.data.total_record.rejected
            });
          }
        });
      }
    });
  }

  handleChange(event) {
    this.setState({ calcelValue: event.target.value });
  }

  callback(key) {
    this.setState({
      callData: true,
      show_res_message: false
    });
    if (key === "1") {
      GetData("/application-status?status=approved").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataApproved: responseJsonData,
            approved_record: responseJsonData.data.total_record.approved,
            pending_record: responseJsonData.data.total_record.pending,
            need_review_record: responseJsonData.data.total_record.need_review,
            cancelled_record: responseJsonData.data.total_record.cancelled,
            rejected_record: responseJsonData.data.total_record.rejected
          });
        }
      });
    } else if (key === "2") {
      GetData("/application-status?status=pending").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataPending: responseJsonData,
            approved_record: responseJsonData.data.total_record.approved,
            pending_record: responseJsonData.data.total_record.pending,
            need_review_record: responseJsonData.data.total_record.need_review,
            cancelled_record: responseJsonData.data.total_record.cancelled,
            rejected_record: responseJsonData.data.total_record.rejected
          });
        }
      });
    } else if (key === "3") {
      GetData("/application-status?status=need_review").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataNeedReview: responseJsonData,
            approved_record: responseJsonData.data.total_record.approved,
            pending_record: responseJsonData.data.total_record.pending,
            need_review_record: responseJsonData.data.total_record.need_review,
            cancelled_record: responseJsonData.data.total_record.cancelled,
            rejected_record: responseJsonData.data.total_record.rejected
          });
        }
      });
    } else if (key === "4") {
      GetData("/application-status?status=cancelled").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataCancelled: responseJsonData,
            approved_record: responseJsonData.data.total_record.approved,
            pending_record: responseJsonData.data.total_record.pending,
            need_review_record: responseJsonData.data.total_record.need_review,
            cancelled_record: responseJsonData.data.total_record.cancelled,
            rejected_record: responseJsonData.data.total_record.rejected
          });
        }
      });
    } else if (key === "5") {
      GetData("/application-status?status=rejected").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataDeclined: responseJsonData,
            approved_record: responseJsonData.data.total_record.approved,
            pending_record: responseJsonData.data.total_record.pending,
            need_review_record: responseJsonData.data.total_record.need_review,
            cancelled_record: responseJsonData.data.total_record.cancelled,
            rejected_record: responseJsonData.data.total_record.rejected
          });
        }
      });
    }
  }

  onConfirmSuccess = () => {
    this.setState({
      showSucess: false,
      visible: false,
      visibleCalcel: "",
      remark: ""
    });
    this.removeDataPending();
  };

  handleSubmitCalcel = e => {
    e.preventDefault(); // Stop form submit
    this.setState({ submitted: true });
    const { dataDetail, calcelValue } = this.state;
    let data = {
      application_submission_id: dataDetail.application_submission_id,
      status: "cancelled",
      remark: calcelValue
    };
    PutData("/application-status", data).then(result => {
      let responseJson = result;
      if (responseJson.status === 1) {
        this.setState({
          visibleCalcel: false,
          showSucess: true,
          response_message: responseJson.message
        });
      } else {
        this.setState({
          showError: true,
          response_message: responseJson.message
        });
      }
    });
  };
  removeDataPending = () => {
    this.setState({
      callData: true,
      show_res_message: false
    });

    GetData("/application-status?status=pending").then(res => {
      let responseJsonData = res;
      if (responseJsonData.status === 1) {
        this.setState({
          loading: false,
          callData: false,
          visible: false,
          visibleCalcel: false,
          calcelValue: "",
          dataPending: responseJsonData,
          approved_record: responseJsonData.data.total_record.approved,
          pending_record: responseJsonData.data.total_record.pending,
          need_review_record: responseJsonData.data.total_record.need_review,
          cancelled_record: responseJsonData.data.total_record.cancelled,
          rejected_record: responseJsonData.data.total_record.rejected
        });
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
      visibleCalcel: false
    });
  };

  showCancel = e => {
    this.setState({
      visible: false,
      visibleCalcel: true
    });
  };

  viewDetail = e => {
    let data = this.state.dataApproved.data.row[
      e.target.getAttribute("data-id")
    ];

    if (data) {
      this.setState({
        visible: true,
        dataDetail: data
      });
    }
  };
  viewDetailRow(index) {
    let data = this.state.dataApproved.data.row[index];
    if (data) {
      this.setState({
        visible: true,
        dataDetail: data
      });
    }
  }

  viewDetailPending = e => {
    let index = e.target.getAttribute("data-id");
    let data = this.state.dataPending.data.row[index];
    if (data) {
      this.setState({
        visible: true,
        dataDetail: data,
        dataPendingIndex: index
      });
    }
  };

  viewDetailNeedReview = e => {
    let data = this.state.dataNeedReview.data.row[
      e.target.getAttribute("data-id")
    ];
    if (data) {
      this.setState({
        visible: true,
        dataDetail: data
      });
    }
  };
  viewDetailCalcelled = e => {
    let data = this.state.dataCancelled.data.row[
      e.target.getAttribute("data-id")
    ];
    if (data) {
      this.setState({
        visible: true,
        dataDetail: data
      });
    }
  };

  viewDetailReject = e => {
    let data = this.state.dataDeclined.data.row[
      e.target.getAttribute("data-id")
    ];
    if (data) {
      this.setState({
        visible: true,
        dataDetail: data
      });
    }
  };

  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      `/file?filecode=${id}&type=application_file`,
      name
    );
  };
  handleCancel = e => {
    this.setState({
      visibleCalcel: false,
      visible: false,
      calcelValue: ""
    });
  };

  actionText() {
    switch (this.state.dataDetail.status) {
      case "approved": return "Approved";
      case "need_review": return "Reviewed"
      case "rejected": return "Rejected";
      case "cancelled": return "Cancelled";
      case "pending":
      default: return "Uploaded";
    }
  }

  render() {
    let content;
    let res_message;
    if (this.state.show_res_message) {
      if (this.state.response_status === 0) {
        res_message = (
          <div className="alert alert-danger">
            {this.state.response_message}
          </div>
        );
      } else if (this.state.response_status === 1) {
        res_message = (
          <div className="alert alert-success">
            {this.state.response_message}
          </div>
        );
      }
    }

    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div className="site-application-status">
            <HeaderContent message="Application Status" />
            {this.state.callData === true && <LoadPage />}
            <Tabs
              defaultActiveKey="1"
              animated="false"
              onChange={this.callback}
            >
              <TabPane tab={"Approved " + this.state.approved_record} key="1">
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataApproved.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}

                          <th className="width-160">Approved Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <ApplicationFeed
                            dataFeed={this.state.dataApproved}
                            viewDetail={this.viewDetail}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
              <TabPane tab={"Pending " + this.state.pending_record} key="2">
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    {res_message}
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataPending.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}

                          <th className="width-160">Submitted Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <ApplicationFeed
                            dataFeed={this.state.dataPending}
                            viewDetail={this.viewDetailPending}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
              <TabPane
                tab={"Need Review " + this.state.need_review_record}
                key="3"
              >
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataNeedReview.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}

                          <th className="width-160">Submitted Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <ApplicationFeed
                            dataFeed={this.state.dataNeedReview}
                            viewDetail={this.viewDetailNeedReview}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
              <TabPane tab={"Cancelled " + this.state.cancelled_record} key="4">
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataCancelled.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}

                          <th className="width-160">Cancelled Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <ApplicationFeed
                            dataFeed={this.state.dataCancelled}
                            viewDetail={this.viewDetailCalcelled}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
              <TabPane tab={"Rejected " + this.state.rejected_record} key="5">
                {this.state.callData === false ? (
                  <div className="tableWidth">
                    <table className="table table-striped">
                      <thead>
                        <tr className="tableThColor">
                          {this.state.dataDeclined.data.header.map(role => (
                            <th>{role.display_name}</th>
                          ))}

                          <th className="width-160">Rejected Date</th>
                          <th>
                            <i class="fa fa-cogs" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.callData === false && (
                          <ApplicationFeed
                            dataFeed={this.state.dataDeclined}
                            viewDetail={this.viewDetailReject}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div />
                )}
              </TabPane>
            </Tabs>

            <Modal
              title="View Application Form"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              className="applicationModal"
            >
              <div className="modalStatus modal-view-application">
                <table>
                  <tbody>
                    <tr>
                      <td width="40%">
                        <b>Roles:</b>
                      </td>
                      <td>
                        {this.state.dataDetail.ndid_role_id.length > 0 && (
                          <FeedRoleMulti
                            data={this.state.dataDetail.ndid_role_id}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status:</b>
                      </td>
                      <td>
                        <span className="status-text">
                          {this.state.dataDetail.status === "need_review"
                            ? "Need Review"
                            : this.state.dataDetail.status}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Submitted Date:</b>
                      </td>
                      <td>{this.state.dataDetail.submited_date}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{this.actionText()} Date:</b>
                      </td>
                      <td>{this.state.dataDetail.approved_date}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{this.actionText()} By:</b>
                      </td>
                      <td>{this.state.dataDetail.approved_by_name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Application Form:</b>
                      </td>
                      <td>
                        <ul className="ulModal">
                          <ApplicationFeedFile
                            dataFeed={this.state.dataDetail.application_file}
                            handelDownloadFile={this.handelDownloadFile}
                          />
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Supported File(s):</b>
                      </td>
                      <td>
                        <ul className="ulModal">
                          <ApplicationFeedFile
                            dataFeed={this.state.dataDetail.document_file}
                            handelDownloadFile={this.handelDownloadFile}
                          />
                        </ul>
                      </td>
                    </tr>

                    {this.state.dataDetail.remark != null && (
                      <tr>
                        <td>
                          <b>Remark:</b>
                        </td>
                        <td>
                          {this.state.dataDetail.remark
                            ? this.state.dataDetail.remark
                            : "-"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.dataDetail.status === "pending" && (
                  <div className="block-btn-footer">
                    <button
                      className="btn btn-danger"
                      onClick={this.showCancel}
                    >
                      Cancel Request
                    </button>
                  </div>
                )}
              </div>
            </Modal>
            <Modal
              title="Cancel Request"
              visible={this.state.visibleCalcel}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              className="cancelRequestModal"
            >
              <div className="modal-cancel-request">
                <h5>Are you sure you want to cancel this request?</h5>
                <p>Add note to describe your reason:</p>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.calcelValue}
                  onChange={this.handleChange}
                />
                <div className="block-btn-footer">
                  <button className="btn-keeep" onClick={this.handleCancel}>
                    Keep Request
                  </button>
                  <button
                    className="btn-cancel"
                    onClick={this.handleSubmitCalcel}
                  >
                    Cancel Request
                  </button>
                </div>
              </div>
            </Modal>
            <SweetAlert
              show={this.state.showSucess}
              type="success"
              title=""
              text={this.state.response_message}
              onConfirm={this.onConfirmSuccess}
            />
            <SweetAlert
              show={this.state.showError}
              type="error"
              title=""
              text={this.state.response_message}
              onConfirm={() => this.setState({ showError: false })}
            />
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

export { ApplicationStatusPage };
