import React from "react";
// import { Link } from "react-router-dom";
// import "./index.css";
import { PostData } from "../../_services";
import { Input } from "antd";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";

const SweetAlert = withSwalInstance(swal);

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      canAccess: false,

      mismatch: false,
    }
  }

  onCloseModal = () => {
    this.setState({
      responseSuccess: undefined,
    });
  }

  onInputChange = (event) => {
    const field = event.target.getAttribute('data');
    const value = event.target.value;
    this.setState(() => {
      if (field.indexOf('newPass') === 0) {
        return {[field]: value, mismatch: false};
      } else {
        return {[field]: value};
      }
    });
  }
  
  onSubmit = async (event) => {
    event.preventDefault();

    if (this.state.newPass !== this.state.newPassConfirm) {
      this.setState({
        mismatch: true,
      });
      return; 
    }

    this.setState({ submitting: true });  
    try {
      const result = await PostData('/users/password/change', {
        old_password: this.state.curPass,
        new_password: this.state.newPass,
      });
      this.setState({
        responseSuccess: result.status === 1,
        responseMessage: result.message,
        submitting: false,
        curPass: '',
        newPass: '',
        newPassConfirm: '',
      });
    } catch (err) {
      this.setState({
        responseSuccess: false,
        responseMessage: 'Something went wrong. Please try again later',
        submitting: false,
      });
    }
  }

  componentDidMount() {
    this.initailize();
  }

  async initailize() {
    try {
      const permission = await CheckPermission('/change_password');
      if (permission !== 1) {
        this.setState({
          canAccess: false,
          isLoading: false
        });
        return;
      }
    } catch (err) {
      this.setState({
        canAccess: false,
        isLoading: false
      });
      return;
    }

    this.setState({
      canAccess: true,
      isLoading: false,
    });
  }

  render() {
    if (this.state.isLoading) {
      return <div><LoadPage /></div>
    }

    if (!this.state.canAccess) {
      return <div><NotPermission /></div>
    }

    return (
      <div className="site-application-status">
        <HeaderContent message="Change Password" />
        <div className="col-md-7">
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Current Password<span style={{color: '#c61f0c'}}>*</span></label>
              <Input 
                type="password"
                size="large" 
                value={this.state.curPass} 
                data="curPass" 
                placeholder="Type current password" 
                onChange={this.onInputChange} 
                required
              />
            </div>
            <div className={`form-group ${this.state.mismatch ? 'has-error' : ''}`}>
              <label>New Password<span style={{color: '#c61f0c'}}>*</span></label>
              <Input 
                type="password"
                size="large" 
                value={this.state.newPass} 
                data="newPass" 
                placeholder="Type new password" 
                onChange={this.onInputChange} 
                required
              />
            </div>
            <div className={`form-group ${this.state.mismatch ? 'has-error' : ''}`}>
              <label>Confirm New Password<span style={{color: '#c61f0c'}}>*</span></label>
              <Input 
                type="password"
                size="large" 
                value={this.state.newPassConfirm} 
                data="newPassConfirm" 
                placeholder="Confirm new password" 
                onChange={this.onInputChange} 
                required
              />
              {this.state.mismatch && (
                <div className="help-block">Password mismatch</div>
              )}
            </div>
            <div className="form-group text-right">
              <button
                className="btn-submit btn-upload-contract"
                value="Change Password"
                disabled={this.state.submitting}
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
        <SweetAlert
          show={this.state.responseSuccess !== undefined}
          type={this.state.responseSuccess === true ? 'success' : 'error'}
          title=""
          text={this.state.responseMessage}
          onConfirm={this.onCloseModal}
        />
      </div>
    );
  }
}

export { ChangePassword };
