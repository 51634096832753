import React from "react";
import logo from "./NDID_logo_transparent.png";
import { PostData } from "../../_services";
import { Link } from "react-router-dom";

class ForgetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      username: "",
      submitting: false,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitting: true });

    await PostData('/users/password/request_reset', {
      email: this.state.email,
      username: this.state.username,
    });

    this.setState({
      submitting: false,
      submitted: true,
    });
  }

  resetPasswordForm() {
    const { username, email, submitting } = this.state;
    return (
      <form name="form" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="useranme">Username</label>
          <div className="inputUsername">
            <i className="fa fa-user" aria-hidden="true"/>
          </div>
          <input
            type="text"
            className="inputField form-control"
            name="username"
            value={username}
            placeholder="Type your username"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <div className="inputUsername">
            <i className="fa fa-envelope" aria-hidden="true"/>
          </div>
          <input
            type="email"
            className="inputField form-control"
            name="email"
            value={email}
            placeholder="Type your email"
            onChange={this.handleChange}
            required
          />
        </div>
        <br />
        <div>
          If you forget your username or email, please contact <a href="mailto:it_support@ndid.co.th">NDID IT Support</a> for further assistence.
        </div>
        <br />
        <div className="form-group">
          <button className="btn buttonLogin">Reset Password</button>
          {submitting && (
            <img
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              alt=""
              style={{width: 'auto'}}
            />
          )}
        </div>
      </form>
    );
  }

  afterSubmittedDiv() {
    return (
      <div className="text-center">
        <h4>
          We have sent an email to {this.state.email}.
          <br /><br />
          Please check your inbox (or junk box) and follow instructions to reset your password.
          <br /><br />
        </h4>
      </div>
    )
  }

  render() {
    const { submitted } = this.state;

    return (
      <div className="siteLogin login">
        <div className="blockLogin">
          <Link to={(process.env.PUBLIC_URL || '')}>
            <img
              src={logo}
              alt="NDID Digital Identitfy for All"
              className="logo"
            />
          </Link>

          {submitted ? 
            this.afterSubmittedDiv() : 
            this.resetPasswordForm()
          }
        </div>
      </div>
    );
  }
}

export { ForgetPage };
