import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
class SignOffFeedFile extends Component {
  render() {
    let dataFeed = "";
    if (this.props.dataFeed.document_history.length > 0) {
      dataFeed = this.props.dataFeed.document_history.map(
        function(feedData, index) {
          return (
            <tr>
              <td width="80%">
                <a
                  href
                  data-id={feedData.filecode}
                  data-value={feedData.filename}
                  onClick={this.props.handelDownloadFile}
                >
                  {feedData.filename}
                </a>
              </td>
              <td>
                <font className="textUploadDate">{feedData.upload_date}</font>
              </td>
            </tr>
          );
        },

        this
      );
    } else {
      dataFeed = <RecordNotFound cols="2" />;
    }

    return dataFeed;
  }
}

export default SignOffFeedFile;
