import React from "react";
import {} from "antd";
import { connect } from "react-redux";
import { PostData } from "../../_services";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import "./index.css";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";

const SweetAlert = withSwalInstance(swal);

class ContactusPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      submitted: false,
      companyNameValue: "",
      nameValue: "",
      emailValue: "",
      phoneValue: "",
      topicValue: "",
      helpValue: "",
      showSucess: false,
      showError: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onConfirmSuccess = this.onConfirmSuccess.bind(this);
  }

  state = { visible: false };
  componentDidMount() {
    CheckPermission("/contact").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        this.setState({
          loading: false,
          callData: false
        });
      }
    });
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.setState({ submitted: true });
    const {
      companyNameValue,
      nameValue,
      emailValue,
      phoneValue,
      topicValue,
      helpValue
    } = this.state;
    if (
      companyNameValue.length > 0 &&
      nameValue.length > 0 &&
      emailValue.length > 0 &&
      phoneValue.length > 0
    ) {
      let data = {
        company_name: companyNameValue,
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
        topic: topicValue,
        message: helpValue
      };
      PostData("/contact", data).then(result => {
        let responseJson = result;
        if (responseJson.status === 1) {
          this.setState({
            companyNameValue: "",
            nameValue: "",
            emailValue: "",
            phoneValue: "",
            topicValue: "",
            helpValue: "",
            showSucess: true
          });
        } else {
          this.setState({
            showError: true
          });
        }
        this.setState({
          submitted: false,
          response_message: responseJson.message
        });
      });
    } else {
      this.setState({
        response_status: 0,
        response_message: "Please Input "
      });
    }
  }
  onConfirmSuccess() {
    this.setState({
      companyNameValue: "",
      submitted: false,
      nameValue: "",
      emailValue: "",
      phoneValue: "",
      topicValue: "",
      helpValue: "",
      showSucess: false
    });
  }
  topicValue = e => {
    this.setState({
      topicValue: e.target.value
    });
  };
  helpValue = e => {
    this.setState({
      helpValue: e.target.value
    });
  };
  companyNameValue = e => {
    this.setState({
      companyNameValue: e.target.value
    });
  };
  nameValue = e => {
    this.setState({
      nameValue: e.target.value
    });
  };
  emailValue = e => {
    this.setState({
      emailValue: e.target.value
    });
  };
  phoneValue = e => {
    this.setState({
      phoneValue: e.target.value
    });
  };
  render() {
    let content;
    const enabled =
      !this.state.submitted &&
      this.state.companyNameValue.length > 0 &&
      this.state.emailValue.length > 0 &&
      this.state.phoneValue.length > 0 &&
      this.state.topicValue.length > 0 &&
      this.state.helpValue.length > 0;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            <HeaderContent message="Contact Us" />
            <div className="col-md-7 col-xs-7 leftside">
              <div className="contact_map">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      title="Map"
                      className="gmap_canvas"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3874.8653095719824!2d100.574813!3d13.786992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x281e16244d5667c6!2z4LmA4Lih4Li34Lit4LiH4LmE4LiX4Lii4Lig4Lix4LiX4LijIOC4hOC4reC4oeC5gOC4nuC4peC5h-C4geC4i-C5jA!5e0!3m2!1sth!2sth!4v1554191556366!5m2!1sth!2sth"
                    />
                  </div>
                </div>
              </div>
              <div className="detailcontact">
                <div className="detail_head">บริษัท เนชั่นแนลดิจิทัล ไอดี จำกัด</div>
                <div className="detail_message">
                อาคารเมืองไทย-ภัทร คอมเพล็กซ์ ทาวเวอร์บี ชั้น 27 เลขที่ 252/125 (A-B) ถ.รัชดาภิเษก ห้วยขวาง กรุงเทพมหานคร 10310
                </div>
              </div>
              <div className="detailcontact">
                <div className="detail_head">โทรศัพท์</div>
                <div className="detail_message"><a href="tel:+66 64-187-2804">+66 64-187-2804</a></div>
              </div>
              <div className="detailcontact">
                <div className="detail_head">อีเมล</div>
                <div className="detail_message"><a href="mailto:info@ndid.co.th">info@ndid.co.th</a></div>
              </div>
            </div>
            <div className="col-md-5 col-xs-5 rightside">
              <form onSubmit={this.onFormSubmit}>
                <div className="detailcontact">
                  <div className="contact_head_add">Contact From</div>
                  <div className="contact_message_add">
                  เรายินดีที่จะช่วยเหลือคุณ โปรดให้รายละเอียดกับเรา และเราจะติดต่อกลับหาคุณ
                  </div>
                </div>

                <div className="detailcontact">
                  <div className="detail_head_add">
                    Company Name<span className="text-danger">*</span>
                  </div>
                  <div className="detail_message_add">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type your Company Name"
                      onChange={this.companyNameValue}
                      value={this.state.companyNameValue}
                    />
                  </div>
                </div>

                <div className="detailcontact">
                  <div className="detail_head_add">
                    Name<span className="text-danger">*</span>
                  </div>
                  <div className="detail_message_add">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type your Name"
                      onChange={this.nameValue}
                      value={this.state.nameValue}
                    />
                  </div>
                </div>

                <div className="detailcontact">
                  <div className="detail_head_add">
                    Email<span className="text-danger">*</span>
                  </div>
                  <div className="detail_message_add">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Type your Email"
                      onChange={this.emailValue}
                      value={this.state.emailValue}
                    />
                  </div>
                </div>

                <div className="detailcontact">
                  <div className="detail_head_add">
                    Phone<span className="text-danger">*</span>
                  </div>
                  <div className="detail_message_add">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type your Phone"
                      onChange={this.phoneValue}
                      value={this.state.phoneValue}
                    />
                  </div>
                </div>

                <div className="detailcontact">
                  <div className="detail_head_add">
                    Topic<span className="text-danger">*</span>
                  </div>
                  <div className="detail_message_add">
                    <select
                      className="form-control topic-style"
                      id="sel1"
                      onChange={this.topicValue}
                      data-id="topic"
                      value={this.state.topicValue}
                    >
                      <option value="">Choose your Topic</option>
                      <option value="Member Application">
                        Member Application
                      </option>
                      <option value="Testing">Testing</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div className="detailcontact">
                  <div className="detail_head_add">
                    How can we help you?<span className="text-danger">*</span>
                  </div>
                  <div className="detail_message_add">
                    <textarea
                      rows="6"
                      className="form-control"
                      onChange={this.helpValue}
                      value={this.state.helpValue}
                    />
                  </div>
                </div>

                <button
                  className="btn btn-info contactPage"
                  disabled={!enabled}
                >
                  Send
                </button>
              </form>
            </div>
            <SweetAlert
              show={this.state.showSucess}
              type="success"
              title=""
              text={this.state.response_message}
              onConfirm={this.onConfirmSuccess}
            />
            <SweetAlert
              show={this.state.showError}
              type="error"
              title=""
              text={this.state.response_message}
              onConfirm={() => this.setState({ showError: false })}
            />
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedProfilePage = connect(mapStateToProps)(ContactusPage);
export { connectedProfilePage as ContactusPage };
