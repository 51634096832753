import React from "react";
import { connect } from "react-redux";
import "./aboutus.css";
import aboutUsImg1 from "./aboutus-1.jpg";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";

class AboutusPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true
    };
  }

  state = { visible: false };
  componentDidMount() {
    CheckPermission("/aboutus").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        this.setState({
          loading: false
        });
      }
    });
  }

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            <HeaderContent message="About Us" />
            <div className="aboutimage">
              <img src={aboutUsImg1} alt="" />
            </div>
            <div className="abouttext">
            เมื่อวันที่ 24 มกราคม 2561 บริษัท เนชั่นแนลดิจิทัลไอดี จำกัด ได้จัดตั้งขึ้นด้วยความร่วมมือระหว่างภาครัฐโดยสำนักงานพัฒนาธุรกรรมทางอิเล็กทรอนิกส์ (องค์การมหาชน) หรือ ETDA และภาคเอกชนที่ให้การสนับสนุนทั้งทรัพยากรณ์ความรู้และการเงิน เนื่องจากรัฐบาลได้มีความตระหนักถึงความสำคัญของการพิสูจน์และยืนยันตัวตนทางดิจิทัล ซึ่งจะช่วยอำนวยความสะดวกให้การประกอบธุรกิจและการทำธุรกรรมต่างๆ ในยุคดิจิทัล สอดคล้องกับนโยบาย Thailand 4.0 โดยมีวิสัยทัศน์ในการเป็นผู้นำในการสร้างแพลทฟอร์มตลอดจนระบบการพิสูจน์แลยืนยันตัวตนทางดิจิทัลอันเป็นที่เชื่อถือไว้วางใจ อันจะนำไปสู่การสร้างสรรค์บริการและธุรกิจใหม่ๆ ในยุคดิจิทัล
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}
function mapStateToProps(state) {
  return {
    state
  };
}

const connectedProfilePage = connect(mapStateToProps)(AboutusPage);
export { connectedProfilePage as AboutusPage };
