import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";

import { connect } from "react-redux";
import { history } from "./_helpers";
import { alertActions } from "./_actions";
import {
  PrivateRoute,
  Header,
  NotFound,
  Footer,
  Sidebar,
  LogoutPage
} from "./_components";
import { LoginPage, ForgetPage, ResetPasswordPage } from "./Page/LoginPage";
import { MemberProfile } from "./Page/ProfilePage";
import { NewsPage, NewsDetail } from "./Page/NewsPage";
import { ProcessOverview } from "./Page/MemberProcessPage/Overview";
import { ProcessUAT } from "./Page/MemberProcessPage/UAT";
import { ProcessNFT } from "./Page/MemberProcessPage/NFT";
import { ProcessApplication } from "./Page/MemberProcessPage/Application";
import { MemberMQAPage } from "./Page/MemberMQAPage";
import { ResourcesPage } from "./Page/ResourcesPage";
import { MemberSITPage } from "./Page/MemberSITPage";
import { AboutusPage } from "./Page/AboutusPage";
import { ContactusPage } from "./Page/ContactusPage";
import { FAQPage } from "./Page/FAQPage";
import { ChangePassword } from "./Page/ChangePassword";
import { ApplicationPage, MemberContractPage } from "./Page/ApplicationPage";
import { ApplicationStatusPage } from "./Page/ApplicationPage/application-status";
import { CheckPermissionMenu } from "./_helpers/permission";
import "antd/dist/antd.css";
import "./index.css";
import "./App.css";
import "./fonts/font-awesome/css/font-awesome.min.css";
import "./fonts/THSarabunNew.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.state = {
      data: "",
      loading: true,
      redirect: false,
      userData: this.props.authentication,
      dataPermissiom: "",
      activeMenu: ""
    };

    history.listen((location, action) => {
      // clear alert on location change
      let path = location.pathname;
      if (
        path === "/" ||
        path === "/home" ||
        path === "/news" ||
        path === "/profile" ||
        path === "/resource" ||
        path === "/aboutus" ||
        path === "/contact" ||
        path === "/faq" ||
        path === "/change_password"
      ) {
        this.setState({
          activeMenu: "Home"
        });
      } else if (
        path === "/application-submission" ||
        path === "/application-status" ||
        path === "/member-contract"
      ) {
        this.setState({
          activeMenu: "App"
        });
      } else if (
        path === "/process" ||
        path === "/process/sit" ||
        path === "/process/uat" ||
        path === "/process/application-review" ||
        path === "/process/nft" ||
        path === "/process/mqa"
      ) {
        this.setState({
          activeMenu: "Process"
        });
      }

      CheckPermissionMenu(location.pathname).then(result => {
        if (result.status === 1) {
          this.setState({
            loading: false,
            dataPermissiom: result
          });
        } else {
          this.setState({
            loading: true
          });
        }
      });

      dispatch(alertActions.clear());
    });
  }
  componentDidMount() {
    CheckPermissionMenu(window.location.pathname).then(result => {
      if (result.status === 1) {
        this.setState({
          loading: false,
          dataPermissiom: result
        });
      } else {
        this.setState({
          loading: true
        });
      }
    });
  }

  render() {
    const { authentication } = this.props;

    let sidebar = "";
    if (authentication.loggedIn === true) {
      if (this.state.loading === false) {
        sidebar = <Sidebar permission={this.state.dataPermissiom} />;
      }
    }

    return (
      <div className="">
        <div className="">
          <div className="">
            {!authentication.loggedIn && (
              <div className="siteLogin">
                <Router history={history}>
                  <div>
                    <Switch>
                      <Redirect exact from="/" to="/login" />
                      <Redirect from="/news" to="/login" />
                      <Redirect from="/logout" to="/login" />
                      <Redirect from="/process/uat" to="/login" />
                      <Redirect from="/process/sit" to="/login" />
                      <Redirect from="/process/nft" to="/login" />
                      <Redirect
                        from="/process/application-review"
                        to="/login"
                      />
                      <Redirect from="/process/mqa" to="/login" />
                      <Redirect from="/process" to="/login" />
                      <Redirect from="/resource" to="/login" />
                      <Redirect from="/aboutus" to="/login" />
                      <Redirect from="/contact" to="/login" />
                      <Redirect from="/faq" to="/login" />
                      <Redirect from="/application-submission" to="/login" />
                      <Redirect from="/application-status" to="/login" />
                      <Redirect from="/membership-contract" to="/login" />
                      <Redirect from="/profile" to="/login" />

                      <Route path="/login" component={LoginPage} />
                      <Route path="/forget" component={ForgetPage} />
                      <Route path="/reset/:id/:token" component={ResetPasswordPage} />
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </Router>
              </div>
            )}
            {authentication.loggedIn === true && (
              <Router history={history}>
                <div>
                  <Header
                    permission={this.state.dataPermissiom}
                    activeMenu={this.state.activeMenu}
                  />
                  <div className="clarfix" />
                  <div className="bodyContent">
                    <div className="container">
                      {sidebar}
                      <div className="col-lg-9 col-md-9 col-xs-12">
                        <div className="site-content" />
                        <Switch>
                          <Route exact path="/" component={NewsPage} />

                          <Redirect from="/login" to="/" />

                          <PrivateRoute path="/home" component={NewsPage} />
                          <PrivateRoute
                            path="/news/:id"
                            component={NewsDetail}
                          />
                          <PrivateRoute path="/news" component={NewsPage} />
                          <PrivateRoute path="/logout" component={LogoutPage} />

                          <PrivateRoute
                            path="/process/uat"
                            component={ProcessUAT}
                          />
                          <PrivateRoute
                            path="/process/nft"
                            component={ProcessNFT}
                          />
                          <PrivateRoute
                            path="/process/application-review"
                            component={ProcessApplication}
                          />
                          <PrivateRoute
                            path="/process/mqa"
                            component={MemberMQAPage}
                          />
                          <PrivateRoute
                            path="/process/sit"
                            component={MemberSITPage}
                          />
                          <PrivateRoute
                            exact
                            path="/process"
                            component={ProcessOverview}
                          />
                          <PrivateRoute
                            path="/resource"
                            component={ResourcesPage}
                          />
                          <PrivateRoute
                            path="/aboutus"
                            component={AboutusPage}
                          />
                          <PrivateRoute
                            path="/contact"
                            component={ContactusPage}
                          />
                          <PrivateRoute path="/faq" component={FAQPage} />

                          <PrivateRoute
                            path="/application-submission"
                            component={ApplicationPage}
                          />
                          <PrivateRoute
                            path="/application-status"
                            component={ApplicationStatusPage}
                          />
                          <PrivateRoute
                            path="/membership-contract"
                            component={MemberContractPage}
                          />

                          <PrivateRoute
                            path="/profile"
                            component={MemberProfile}
                          />

                          <PrivateRoute
                            path="/change_password"
                            component={ChangePassword}
                          />

                          <PrivateRoute component={NotFound} />
                        </Switch>
                      </div>
                    </div>
                  </div>

                  <Footer />
                </div>
              </Router>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, dataPermissiom } = state;
  return {
    alert,
    authentication,
    dataPermissiom
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
