import React from "react";
import "./footer.css";
import { connect } from "react-redux";
import "./footer.css";
class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <span>Copyright © 2019. All rights reserved. </span>
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  return {
    alert,
    authentication
  };
}

const connectedApp = connect(mapStateToProps)(Footer);
export { connectedApp as Footer };
