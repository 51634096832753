import React, { Component } from "react";
import RecordNotFound from "../../_components/RecordNotFound";
import { Link } from "react-router-dom";
import "./index.css";

import createDOMPurify from 'dompurify';
import { JSDOM } from 'jsdom';

const window = (new JSDOM('')).window;
const DOMPurify = createDOMPurify(window);

class NewsListShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_url: [],
      pic_url: ""
    };
  }

  render() {
    let dataFeed = "";

    if (this.props.dataFeed.length > 0) {
      dataFeed = this.props.dataFeed.map(
        function(feedData, index) {
          return (
            <div key={index} className="col-xs-6 col-lg-4">
              <Link to={"news/" + feedData.id} role="button">
                <div className="cardItem">
                  <div className="news-image">
                    <img
                      className="thumbnail"
                      alt=""
                      src={
                        (process.env.REACT_APP_API_URL || '/api') +
                        "/file-public?type=news&filecode=" +
                        feedData.file.filecode
                      }
                    />
                  </div>
                  <div className="descCard">
                    <p className="dateFrom">
                      <span className="date">{feedData.published_date}</span>
                      <span className="from">{feedData.category_name}</span>
                    </p>
                    <h3>{feedData.title}</h3>
                    <p 
                      className="news-detail" 
                      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(feedData.detail)}}
                    >
                    </p>
                    <p>
                      <button className="buttonReadMore btn">อ่านต่อ</button>
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        },

        this
      );
    } else {
      dataFeed = <RecordNotFound cols="1" />;
    }

    return dataFeed;
  }
}

export default NewsListShow;
