import React from "react";
import "./header-content.css";

class HeaderContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.message
    };
  }

  render() {
    return <div className="titleTab">{this.state.message}</div>;
  }
}

export { HeaderContent };
