import React, { Component } from "react";
class RecordNotFound extends Component {
  render() {
    return (
      <tr className="text-center">
        <td colSpan={this.props.cols}>Record not found.</td>
      </tr>
    );
  }
}

export default RecordNotFound;
