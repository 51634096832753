import React from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import "./index.css";
import { HeaderContent, NotPermission } from "../../_components";
import { CheckPermission } from "../../_helpers/permission";
import LoadPage from "../../_components/loading";
import { GetData } from "../../_services";
import NFTFeed from "./NFT-feed";
import { DownloadFiles } from "../../_services/data.service";
import OverviewFeedDocument from "./Resource_feed_document";

const TabPane = Tabs.TabPane;

const thTable = (
  <thead className="trHeaderColor">
    <tr>
      <th>File Name</th>
      <th className="text-center">Role</th>
      <th className="text-center">Status</th>
      <th className="width-20p text-center">Upload Date</th>
    </tr>
  </thead>
);

class ProcessNFT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 1,
      loading: true,
      dataNFT: "",
      dataOverview: "",
      callData: true
    };
    this.callback = this.callback.bind(this);
  }
  callback(key) {
    this.setState({
      callData: true
    });
    if (key === "1") {
      GetData("/member-process/nft/overview").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataOverview: responseJsonData
          });
        }
      });
    } else if (key === "2") {
      GetData("/member-process/nft/summary").then(res => {
        let responseJsonData = res;
        if (responseJsonData.status === 1) {
          this.setState({
            loading: false,
            callData: false,
            dataNFT: responseJsonData
          });
        }
      });
    }
  }
  componentDidMount() {
    CheckPermission("/process/nft").then(result => {
      this.setState({
        data: result
      });
      if (result === 1) {
        GetData("/member-process/nft/overview").then(res => {
          let responseJsonData = res;
          if (responseJsonData.status === 1) {
            this.setState({
              loading: false,
              callData: false,
              dataOverview: responseJsonData
            });
          }
        });
      }
    });
  }
  handelDownloadFilePublic = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");
    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=document_public",
      name
    );
  };

  handelDownloadFile = e => {
    let id = e.target.getAttribute("data-id");
    let name = e.target.getAttribute("data-value");

    DownloadFiles(
      "/file?filecode=" +
        id +
        "&type=member_process_result",
      name
    );
  };

  render() {
    let content;
    if (this.state.data === 1) {
      if (this.state.loading) {
        content = (
          <div>
            <LoadPage />
          </div>
        );
      } else {
        content = (
          <div>
            {this.state.callData === true && <LoadPage />}
            <HeaderContent message="NFT" />
            <div className="uatBlock">
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Overview" key="1" className="titleTabs">
                  {this.state.callData === false ? (
                    <frameElement>
                      <p>
                      Non-Functional Testing (NFT) เป็นขั้นตอนการทดสอบประสิทธิภาพการทำงานของ Node ของสมาชิก เพื่อทดสอบว่าสมาชิกสามารถรองรับ Request ได้ตามเกณฑ์ที่กำหนดหรือไม่ 
                      </p>
                      <div className="mt-20">
                        การทดสอบ NFT มีเงื่อนไขและขั้นตอนดังต่อไปนี้
                      </div>

                      <ul className="list-none">
                        <li>
                        1.	สมาชิกผ่านการทดสอบ Application Review และลงนาม Sign-Off Document เรียบร้อยแล้ว
                        </li>
                        <li>
                        2.	สมาชิกสามารถดาวน์โหลด Application Mock-up สำหรับใช้ในการทดสอบ NFT ได้ตาม link ด้านล่าง
                        </li>
                        <li>
                        3.	NDID นัดหมายวันและเวลาที่จะทำการทดสอบ NFT ร่วมกับสมาชิก ให้สมาชิกติดตั้ง Application Mock-up สำหรับทดสอบให้เรียบร้อย
                        </li>
                        <li>
                        4.	หลังจากสมาชิกผ่านการทดสอบ NFT ตามเกณฑ์ที่กำหนด ทาง NDID จะออกเอกสารสำหรับ NFT Sign-Off ให้กับสมาชิกลงนาม
                        </li>
                        <li>5.	NDID อัพโหลดผลการทดสอบและเอกสาร Sign-Off เพื่อเป็นหลักฐานยืนยันการผ่านการทดสอบของสมาชิก</li>
                      </ul>

                      <div className="documentBlock">
                        <p className="titleDocument">
                          เอกสารประกอบการทดสอบ NFT
                        </p>
                        <ul className="testDoccumentFile">
                          {this.state.callData === false && (
                            <OverviewFeedDocument
                              dataFeed={this.state.dataOverview.data}
                            />
                          )}
                        </ul>
                      </div>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
                <TabPane tab="Summary" key="2">
                  {this.state.callData === false ? (
                    <frameElement>
                      <p className="titleDocument">ผลการทดสอบ NFT</p>
                      <table className="table table-striped tableSummary">
                        {thTable}
                        <tbody>
                          {this.state.callData === false && (
                            <NFTFeed
                              dataFeed={this.state.dataNFT}
                              handelDownloadFile={this.handelDownloadFile}
                            />
                          )}
                        </tbody>
                      </table>
                    </frameElement>
                  ) : (
                    <div />
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
      }
    } else {
      content = <NotPermission />;
    }
    return <div>{content}</div>;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedProcessNFT = connect(mapStateToProps)(ProcessNFT);
export { connectedProcessNFT as ProcessNFT };
